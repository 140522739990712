import React from 'react'
import { ReactComponent as BlotterIcon } from '../assets/blotter.svg'
import { ReactComponent as BlotterSelectIcon } from '../assets/blotter_select.svg'
import { ReactComponent as StaticsIcon } from '../assets/statics.svg'
import { ReactComponent as StaticsSelectIcon } from '../assets/statics_select.svg'
import { ReactComponent as DealZipIcon } from '../assets/deal_zip.svg'
import { ReactComponent as DealZipSelectIcon } from '../assets/deal_zip_select.svg'
import { ReactComponent as DeletedTradesIcon } from '../assets/deleted_trades.svg'
import { ReactComponent as DeletedTradesSelectIcon } from '../assets/deleted_trades_select.svg'
import TradeDelete from '../pages/TradeDelete/TradeDelete'
import DealZipDownload from '../pages/DealExcelDownload/DealExcelDownload'
import Statics from '../pages/Statics/Statics'
import TradeBlotter from '../pages/TradeBlotter/TradeBlotter'

const router = [
  {
    name: 'Statics',
    path: '/statics',
    icon: <StaticsIcon />,
    selectIcon: <StaticsSelectIcon />,
    element: <Statics />
  },
  {
    name: 'Trade Blotter',
    path: '/trade/blotter',
    icon: <BlotterIcon />,
    selectIcon: <BlotterSelectIcon />,
    element: <TradeBlotter />
  },
  {
    name: 'Trade Report',
    path: '/deal/report',
    icon: <DealZipIcon />,
    selectIcon: <DealZipSelectIcon />,
    element: <DealZipDownload />
  },
  {
    name: 'Delete Trades',
    path: '/trade/delete',
    icon: <DeletedTradesIcon />,
    selectIcon: <DeletedTradesSelectIcon />,
    element: <TradeDelete />
  }
]

export default router
