import React from 'react'
import { ButtonAddNew, SaveChangesButton } from '../components/styled/global.style'
import { METHODS, routes } from '../utils/constants'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import { handelNotifications } from '../redux/slices/Notification/notificationSlice'
import axios from 'axios'

dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

export function areObjectsEqual(obj1, obj2) {
  const sortedString1 = JSON.stringify(obj1, Object.keys(obj1).sort())
  const sortedString2 = JSON.stringify(obj2, Object.keys(obj2).sort())
  return sortedString1 === sortedString2
}

export function deleteKeysFromObject(object, deleteKeyList) {
  for (const key of deleteKeyList) {
    delete object[key]
  }
}

export function castObjectBooleanKeyToNumber(object, keyList) {
  for (const key of keyList) {
    object[key] = +object[key]
  }
}

export function arraysObjectEqual(arr1, arr2) {
  // Compare lengths
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort arrays (if order doesn't matter)
  arr1.sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1))
  arr2.sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1))

  // Compare stringified arrays
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export function areArrayEqual(array1, array2) {
  const sortedString1 = JSON.stringify(array1.sort())
  const sortedString2 = JSON.stringify(array2.sort())
  return sortedString1 === sortedString2
}

export function getMethods(data) {
  return Object.keys(data).length ? METHODS.PUT : METHODS.POST
}

export function getModalSubmitButton(data, onClick, disabled) {
  return Object.keys(data).length ? (
    <SaveChangesButton variant="contained" onClick={onClick} disabled={disabled}>
      Save Changes
    </SaveChangesButton>
  ) : (
    <ButtonAddNew variant="contained" onClick={onClick} disabled={disabled}>
      Add New
    </ButtonAddNew>
  )
}

export function disabledSubmit(form, formKeyValidate) {
  for (const key of formKeyValidate) {
    if (typeof form[key] === 'string' && form[key] === '') {
      return true
    } else if (Array.isArray(form[key]) && form[key].length === 0) {
      return true
    }
  }
  return false
}

export function checkSomeLoader(loaderList) {
  loaderList.some(loader => loader === true)
}

export function testPasswordRegex(password) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>'"])(?!.*\s).{8,}$/.test(
    password
  )
}

export function hasNumber(str) {
  return /\d/.test(str)
}

export function hasSymbol(str) {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str)
}

export function changeUtcTimeToLocal(utcTime, format) {
  return dayjs.utc(utcTime).local().format(format)
}

export function changeTimeLocalToUtc(localTime, format) {
  return dayjs(localTime).utc().format(format)
}

export function cleanEmptyField(object) {
  for (const key of Object.keys(object)) {
    if (object[key] === '') {
      delete object[key]
    } else if (Array.isArray(object[key]) && object[key].length === 0) {
      delete object[key]
    }
  }
}

export function checkObjectAndArrayWithStringify(array, objectToFind) {
  const serializedObjectToFind = JSON.stringify(objectToFind)
  return array.every(item => JSON.stringify(item) === serializedObjectToFind)
}

export const downlandExcelTrades = async (dispatch, token, id, params = {}, title) => {
  try {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.dealExcel, `${Number(id)}`)
    const response = await axios.get(url, {
      headers: { Authorization: token },
      responseType: 'blob',
      params
    })
    const extension = response.data.type.includes('csv') ? '.csv' : '.zip'
    const urlExcelFile = window.URL.createObjectURL(new Blob([response.data]))
    const a = document.createElement('a')

    a.href = urlExcelFile
    a.download = `${title}${extension}`
    document.body.appendChild(a)
    a.click()
    a.remove()

    window.URL.revokeObjectURL(urlExcelFile)
  } catch (error) {
    dispatch(
      handelNotifications({
        id: Date.now(),
        type: 'error',
        message: 'deal csv not found'
      })
    )
  }
}

export const paginationHandler = (data, page, { setDisabled, disabled }) => {
  if (data.length === 0) {
    setDisabled({ prev: true, next: true })
  }
  if (page === 1 && data.length < 100) {
    setDisabled({ prev: true, next: true })
  } else if (page === 1 && data.length === 100) {
    setDisabled({ prev: true, next: false })
  } else if (page > 1 && data.length < 100) {
    setDisabled({ prev: true, next: true })
  } else if (page > 1 && data.length === 100) {
    setDisabled({ prev: false, next: false })
  }
}

export const copyId = (dispatch, data, title = 'deal') => {
  navigator.clipboard.writeText(data).then(() => {
    dispatch(
      handelNotifications({
        id: Date.now(),
        type: 'success',
        message: `${title} data copy to clipboard`
      })
    )
  })
}

export function numberFormat(number, decimalPoint = 2) {
  if (isNaN(parseFloat(number))) return ''
  const [integerPart, decimalPart] = String(Number(number).toFixed(decimalPoint)).split('.')
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return `${formattedIntegerPart}.${decimalPart}`
}

export const createBloombergCurrency = ({ counter_currency, counter_currency_country }) => {
  return {
    action: 'new',
    security: `${counter_currency}USD Curncy`,
    attributes: ['LAST_PRICE'],
    order_id: 20,
    base_currency: counter_currency,
    base_currency_country: counter_currency_country,
    counter_currency: 'USD',
    counter_currency_country: 'USA'
  }
}
