import React from 'react'
import styled from '@emotion/styled'
import { keyframes, Typography } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { ReactComponent as WarningIcon } from '../../../../assets/warning.svg'
import { ReactComponent as UnionIcon } from '../../../../assets/union.svg'
const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const NewText = styled(Typography)(({ theme, isdeal }) => ({
  color: theme.palette.button.addNew.primary,
  leadingTrim: 'both',
  textEdge: 'cap',
  marginLeft: isdeal === 'true' ? '35px' : '20px',
  marginRight: '10px',
  fontFamily: 'Nunito',
  fontSize: '12px',
  animation: `${fadeOutAnimation} ease-out 3s forwards`
}))

export const WarningIconStyle = styled(WarningIcon)(({ isdeal }) => ({
  marginLeft: '35px',
  marginRight: '25px'
}))

export const UnionIconStyle = styled(UnionIcon)(() => ({
  marginLeft: '18px',
  marginRight: '27px'
}))

export const SpaceStyle = styled('div')(({ isdeal }) => ({
  marginLeft: isdeal === 'true' ? '75px' : '60px'
}))

export const TooltipStyle = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 15,
    fontFamily: 'Nunito',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color
  }
}))
