import React from 'react'
import PropTypes from 'prop-types'
import './ModalHeader.style.js'
import { DividerStyle, Title } from './ModalHeader.style.js'

const ModalHeader = ({ title }) => {
  return (
    <>
      <Title>{title}</Title>
      <DividerStyle></DividerStyle>
    </>
  )
}

ModalHeader.propTypes = {
  title: PropTypes.string
}

export default ModalHeader
