import * as React from 'react'
import PropTypes from 'prop-types'
import { CheckboxStyle } from '../../../../components/styled/global.style'
import PaperAutoComplete from '../../../../components/PaperAutoComplete/PaperAutoComplete'
import { AutocompleteStyle, TextFieldStyle } from './AutoCompleteCheckBox.style'

const AutoCompleteCheckBox = ({ data = [], filters, name, handelChange }) => {
  const getHeight = () => {
    if (Array.isArray(filters[name]) && filters[name].length > 1) {
      return `${(50 * filters[name].length) / 2}px`
    }
    return `50px`
  }

  const getValue = () => {
    if (Array.isArray(filters[name])) {
      return filters[name]
    }
    return []
  }

  return (
    <AutocompleteStyle
      multiple
      height={getHeight()}
      className="container"
      id="checkboxes-tags-demo"
      value={getValue()}
      options={data}
      disableCloseOnSelect
      onChange={(event, element) => {
        event.target.name = name
        handelChange(event, element)
      }}
      getOptionLabel={option => option}
      renderOption={(props, option) => {
        const { ...optionProps } = props
        return (
          <li {...optionProps}>
            <CheckboxStyle
              checked={Array.isArray(filters[name]) && filters[name].includes(option)}
            />
            {option}
          </li>
        )
      }}
      renderInput={(params, index) => <TextFieldStyle key={index} {...params} />}
      ListboxComponent={PaperAutoComplete}
    />
  )
}

AutoCompleteCheckBox.propTypes = {
  data: PropTypes.array,
  handelChange: PropTypes.func,
  filters: PropTypes.object,
  name: PropTypes.string
}

export default AutoCompleteCheckBox
