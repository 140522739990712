import { styled } from '@mui/system'
import { Select, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: `${theme.palette.text.primary} !important`,
  width: '100%',
  '& .MuiPaper-root': {
    backgroundColor: `${theme.palette.separators.primary} !important`,
    hight: '150px',
    '& ul': {
      hight: '150px',
      backgroundColor: `${theme.palette.separators.primary} !important`
    }
  }
}))

export const StyledArrowIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: `${theme.palette.button.save.primary} !important`
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: 'white',
  display: 'block',
  fontFamily: 'Nunito',
  textTransform: 'capitalize',
  backgroundColor: theme.palette.separators.primary,
  '&:hover': {
    backgroundColor: theme.palette.modal.primary
  }
}))

export const StyledSelectPlaceHolder = styled('div')(({ theme }) => ({
  color: 'grey',
  textTransform: 'capitalize',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontWeight: '650',
  backgroundColor: theme.palette.separators.primary
}))
