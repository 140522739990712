import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import useAxios from '../../../../hooks/useAxios.js'
import { DATE_FORMAT, routes } from '../../../../utils/constants.js'
import SelectWithArrow from '../../../SelectWithArrow/SelectWithArrow.js'
import {
  AutocompleteStyle,
  Container,
  InputBaseStyle,
  ModalContainer,
  SubContainer,
  TextFieldModalStyle
} from '../../ModalApp.style.js'
import ModalBottom from '../../components/ModalBottom/ModalBottom.js'
import { changeTimeLocalToUtc, checkSomeLoader } from '../../../../helper/index.js'
import Loader from '../../../Loader/Loader.js'

import PaperAutoComplete from '../../../PaperAutoComplete/PaperAutoComplete.js'
import dayjs from 'dayjs'
import FlagCurrency from '../../../FlagCurrency/FlagCurrency.js'
import { ButtonGroupStyle, OptionContainer, SideButton } from './Deal.style.js'
import BasicDateTimePicker from '../../../DateTimePicker/DateTimePicker.js'
import { webSocketWorkerEvent } from '../../../../services/index.js'
import { useDispatch } from 'react-redux'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice.js'
import { closeModal } from '../../../../redux/slices/Modal/modalSlice.js'
import CurrencyLogo from '../../../CurrencyLogo/CurrencyLogo.js'
import { Label } from '../../../styled/global.style.js'

const Deal = ({ data }) => {
  const dispatch = useDispatch()
  let loaderFirstTime = useRef(true).current
  useEffect(() => {
    const handelMessage = message => {
      if (message.data.type === 'create_deal' && message.data.status === 'ok') {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'success',
            message: 'Deal Made Successfully'
          })
        )
        dispatch(closeModal())
      }
    }

    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  const currencyInitial = { id: 0, name: '', code3: '' }

  const request1 = {
    endPoint: routes.currency
  }
  const request2 = {
    endPoint: routes.side
  }
  const request3 = {
    endPoint: routes.client
  }
  const request4 = {
    endPoint: routes.marketMaker
  }
  const request5 = {
    endPoint: routes.dealTypes
  }

  const { response: currencyData, loading: currencyLoader } = useAxios(request1)
  const { response: sides, loading: sidesLoader } = useAxios(request2)
  const { response: clients, loading: clientsLoader } = useAxios(request3)
  const { response: marketMakers, loading: marketMakersLoader } = useAxios(request4)
  const { response: dealTypes, loading: dealTypesLoader } = useAxios(request5)

  const loaderList = [
    currencyLoader,
    sidesLoader,
    clientsLoader,
    marketMakersLoader,
    dealTypesLoader
  ]

  const [form, setForm] = useState({
    deal_type: '',
    client_side: '',
    base_currency: { id: 0, name: '', code3: '' },
    counter_currency: { id: 0, name: '', code3: '' },
    quantity: '',
    client_price: '',
    market_maker_price: '',
    trade_date: dayjs().format(DATE_FORMAT.REGULAR_DATE_TIME),
    client_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    market_maker_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    client: '',
    market_maker: '',
    trade_origin: 'FXER'
  })

  const disabled = () => {
    return Object.values(form).some(control => !control)
  }

  const submitForm = () => {
    const copyForm = { ...form }
    copyForm.base_currency = form.base_currency.name
    copyForm.counter_currency = form.counter_currency.name
    copyForm.trade_date = changeTimeLocalToUtc(copyForm.trade_date, DATE_FORMAT.REGULAR_DATE_TIME)
    webSocketWorkerEvent.sendEvent({
      type: 'create_deal',
      data: copyForm
    })
  }

  const handelFormChange = (event, element) => {
    const copyForm = { ...form }
    const { name, value } = event.target
    if (['base_currency', 'counter_currency', 'client', 'market_maker'].includes(name)) {
      copyForm[name] = element
    } else if (['client_side', 'deal_type', 'trade_origin'].includes(name)) {
      copyForm[name] = value
    } else if (
      ['quantity', 'client_price', 'market_maker_price'].includes(name) &&
      ((!isNaN(value) && +value > 0) || value === '')
    ) {
      copyForm[name] = value
    }
    setForm(copyForm)
  }

  const handelDateChange = (name, value) => {
    const copyForm = { ...form }
    if (['client_value_date', 'market_maker_value_date'].includes(name)) {
      copyForm[name] = value
    }
    setForm(copyForm)
  }

  if (checkSomeLoader(loaderList) && loaderFirstTime) {
    loaderFirstTime = false
    return <Loader />
  }

  return (
    <ModalContainer>
      <Container>
        <SubContainer>
          <Label>Deal Type *</Label>
          {Array.isArray(dealTypes.data) && (
            <SelectWithArrow
              name="deal_type"
              placeholder="Select Deal Type"
              orderType={form.deal_type}
              onChange={handelFormChange}
              items={dealTypes.data}
            />
          )}
        </SubContainer>

        <SubContainer>
          <Label>Client Side *</Label>
          {Array.isArray(sides.data) && (
            <ButtonGroupStyle variant="outlined">
              {sides.data.map(({ name }, id) => (
                <SideButton
                  name="client_side"
                  key={id}
                  value={name}
                  select={String(form.client_side === name)}
                  onClick={handelFormChange}
                >
                  {name}
                </SideButton>
              ))}
            </ButtonGroupStyle>
          )}
        </SubContainer>
      </Container>
      <Container>
        <SubContainer>
          <Label>Base Currency *</Label>
          {Array.isArray(currencyData.data) && (
            <AutocompleteStyle
              onChange={(event, element) => {
                event.target.name = 'base_currency'
                element = element ?? currencyInitial
                handelFormChange(event, element)
              }}
              size={'small'}
              value={form.base_currency}
              getOptionLabel={option => option.name}
              options={currencyData.data.filter(({ name }) => name !== form.counter_currency.name)}
              renderInput={params => (
                <TextFieldModalStyle {...params} fullWidth placeholder={'Select Currency'} />
              )}
              renderOption={(props, option) => (
                <OptionContainer {...props} key={option.id}>
                  <FlagCurrency countryCode={option.code3} />
                  <CurrencyLogo currencyCode={option.name} />
                  <Typography style={{ marginLeft: 'auto' }}>{option.name} </Typography>
                </OptionContainer>
              )}
              ListboxComponent={PaperAutoComplete}
            />
          )}
        </SubContainer>

        <SubContainer>
          <Label>Counter Currency *</Label>
          {Array.isArray(currencyData.data) && (
            <AutocompleteStyle
              fullWidth
              onChange={(event, element) => {
                event.target.name = 'counter_currency'
                element = element ?? currencyInitial
                handelFormChange(event, element)
              }}
              size={'small'}
              value={form.counter_currency}
              getOptionLabel={option => option.name}
              options={currencyData.data.filter(({ name }) => name !== form.base_currency.name)}
              renderOption={(props, option) => (
                <OptionContainer {...props} key={option.id}>
                  <FlagCurrency countryCode={option.code3} />
                  <CurrencyLogo currencyCode={option.name} />
                  <Typography>{option.name} </Typography>
                </OptionContainer>
              )}
              renderInput={params => (
                <TextFieldModalStyle fullWidth {...params} placeholder={'Select Currency'} />
              )}
              ListboxComponent={PaperAutoComplete}
            />
          )}
        </SubContainer>
      </Container>
      <Container style={{ alignItems: 'center' }}>
        <SubContainer>
          <Label>Quantity *</Label>
          <InputBaseStyle
            value={form.quantity}
            name="quantity"
            onChange={handelFormChange}
            placeholder="0.00"
          />
        </SubContainer>
        <SubContainer>
          <Label>Trade Date *</Label>
          <Box className="date-container">
            <BasicDateTimePicker
              disabled={true}
              name={'trade_date'}
              value={dayjs(form.trade_date)}
              format={DATE_FORMAT.REGULAR_DATE_TIME}
            />
          </Box>
        </SubContainer>
      </Container>
      <Container>
        <SubContainer>
          <Label>Client Value Date *</Label>
          <Box className="date-container">
            <BasicDateTimePicker
              value={dayjs(form.client_value_date)}
              handelDateChange={handelDateChange}
              name={'client_value_date'}
              disabled={false}
              format={DATE_FORMAT.REGULAR_DATE}
            />
          </Box>
        </SubContainer>
        <SubContainer>
          <Label>Market Maker Value Date *</Label>
          <Box className="date-container">
            <BasicDateTimePicker
              value={dayjs(form.market_maker_value_date)}
              handelDateChange={handelDateChange}
              name={'market_maker_value_date'}
              disabled={false}
              format={DATE_FORMAT.REGULAR_DATE}
            />
          </Box>
        </SubContainer>
      </Container>
      <Container>
        <SubContainer>
          <Label>Client Price *</Label>
          <InputBaseStyle
            value={form.client_price}
            name="client_price"
            placeholder={'0.0000'}
            onChange={handelFormChange}
          />
        </SubContainer>
        <SubContainer>
          <Label>Market Maker Price *</Label>
          <InputBaseStyle
            value={form.market_maker_price}
            name="market_maker_price"
            placeholder={'0.0000'}
            onChange={handelFormChange}
          />
        </SubContainer>
      </Container>
      <Container>
        <SubContainer>
          <Label>Client *</Label>
          {Array.isArray(clients.data) && (
            <AutocompleteStyle
              onChange={(event, element) => {
                event.target.name = 'client'
                element = element ?? ''
                handelFormChange(event, element)
              }}
              size={'small'}
              value={form.client}
              options={clients.data.map(({ client_name }) => client_name)}
              renderInput={params => (
                <TextFieldModalStyle fullWidth {...params} placeholder="Select Client" />
              )}
              ListboxComponent={PaperAutoComplete}
            />
          )}
        </SubContainer>
        <SubContainer>
          <Label>Market Maker *</Label>
          {Array.isArray(marketMakers.data) && (
            <AutocompleteStyle
              onChange={(event, element) => {
                event.target.name = 'market_maker'
                element = element ?? ''
                handelFormChange(event, element)
              }}
              size={'small'}
              value={form.market_maker}
              options={marketMakers.data.map(({ market_maker_name }) => market_maker_name)}
              renderInput={params => (
                <TextFieldModalStyle fullWidth {...params} placeholder="Select MM" />
              )}
              ListboxComponent={PaperAutoComplete}
            />
          )}
        </SubContainer>
      </Container>

      <Box>
        <ModalBottom data={data} submitForm={submitForm} disabled={disabled()} />
      </Box>
    </ModalContainer>
  )
}

Deal.propTypes = {
  data: PropTypes.object
}
export default Deal
