export const defaultLayout = {
  lg: [
    { w: 9, h: 6.5, x: 0, y: 0, i: '1', moved: true, static: true },
    { w: 3, h: 6.5, x: 9, y: 0, i: '2', moved: true, static: true }
  ],
  md: [
    { w: 7, h: 6.5, x: 0, y: 0, i: '1', moved: true, static: true },
    { w: 3, h: 6.5, x: 7, y: 0, i: '2', moved: true, static: true }
  ],
  sm: [
    { w: 4, h: 6.5, x: 0, y: 0, i: '1', static: true },
    { w: 2, h: 6.5, x: 4, y: 0, i: '2', static: true }
  ],
  xs: [
    { w: 4, h: 5, x: 0, y: 0, i: '1', static: true },
    { w: 4, h: 5, x: 0, y: 5.5, i: '2', static: true }
  ]
}
