import React, { useCallback, useState } from 'react'
import Status from '../components/Status/Status'
import {
  CheckboxTradeBlotter,
  CopyComponentStyle,
  DownloadComponentStyle,
  TextQuantity
} from '../TradeBlotter.style'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { STAGING_STATUS, TRADE_BLOTTER_STATUS } from '../../../utils/constants'
import CollapseData from '../components/CollapseData/CollapseData'
import CurrencyLogo from '../../../components/CurrencyLogo/CurrencyLogo'
import FlagCurrency from '../../../components/FlagCurrency/FlagCurrency'
import { copyId, downlandExcelTrades, numberFormat } from '../../../helper'
import MenuItem from '../components/MenuItem/MenuItem'
import { CheckboxStyle } from '../../../components/styled/global.style'
import { ReactComponent as DeselectedCheckBox } from '../../../assets/deSelectCheckbox.svg'

const useTradeBlotterAgGridSetting = ({
  dealTradesGroup,
  setDealTradesGroup,
  dealTradesAllocateGroup,
  setDealTradesAllocatedGroup,
  dealsGiveUpGroup,
  setDealsGiveUpGroup,
  tradesGroup,
  setTradesGroup,
  setExpandedRows,
  dealsGroup,
  setDealsGroup,
  cleanCheckBoxState,
  selected,
  setSelected,
  dispatch,
  token,
  pendingData,
  tradeBlotterResponse,
  gridScrollHight,
  setTradeBlotterResponse,
  setPendingData
}) => {
  const [lock, setLock] = useState(false)

  const detailCellRenderer = useCallback(
    params => (
      <CollapseData
        data={params.data}
        handelTradeDeal={handelTradeDeal}
        handelTradeDealChecked={handelTradeDealChecked}
        dealTradesGroup={dealTradesGroup}
      />
    ),
    [dealTradesGroup, selected]
  )

  const handelTradeDeal = useCallback(
    function (trade) {
      const copyDealTradesGroup = JSON.parse(JSON.stringify(dealTradesGroup))
      const copyDealTradesAllocateGroup = JSON.parse(JSON.stringify(dealTradesAllocateGroup))
      const id = Number(trade.id)
      if (!copyDealTradesGroup[id]) {
        copyDealTradesGroup[id] = trade
        setDealTradesGroup(copyDealTradesGroup)
        setSelected(selected + 1)
      } else {
        setSelected(selected - 1)
        delete copyDealTradesGroup[id]
        setDealTradesGroup(copyDealTradesGroup)
      }
      if (!trade.prime_broker) {
        if (!copyDealTradesAllocateGroup[id]) {
          copyDealTradesAllocateGroup[id] = trade
          setDealTradesAllocatedGroup(copyDealTradesAllocateGroup)
        } else {
          setSelected(selected - 1)
          delete copyDealTradesAllocateGroup[id]
          setDealTradesAllocatedGroup(copyDealTradesAllocateGroup)
        }
      }
    },
    [dealTradesGroup, dealTradesAllocateGroup, selected]
  )

  const handelTradeDealChecked = useCallback(
    function (params) {
      const id = Number(params.id)
      return id in dealTradesGroup || id in dealTradesAllocateGroup
    },
    [dealTradesGroup, dealTradesAllocateGroup]
  )

  const isRowMaster = useCallback(dataItem => {
    return dataItem ? Array.isArray(dataItem.trades) : false
  }, [])

  const CurrencyPairCell = prop => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          width: '100%',
          height: '100%'
        }}
      >
        <Typography>{prop.data.base_currency}</Typography>
        <FlagCurrency countryCode={prop.data.base_currency_country} />
        <CurrencyLogo currencyCode={prop.data.base_currency} />
        <Typography>/</Typography>
        <Typography>{prop.data.counter_currency}</Typography>
        <FlagCurrency countryCode={prop.data.counter_currency_country} />
        <CurrencyLogo currencyCode={prop.data.counter_currency} />
      </Box>
    )
  }
  const getRowId = useCallback(function (params) {
    return params.data.id ?? null
  }, [])

  const ClientQuantity = prop => {
    const { client_quantity } = prop.data
    return <TextQuantity quantity={client_quantity}>{numberFormat(client_quantity)}</TextQuantity>
  }

  const handelChecked = useCallback(
    params => {
      if (Array.isArray(params.trades)) {
        return Number(params.id) in dealsGroup
      } else {
        return Number(params.id) in tradesGroup
      }
    },
    [tradesGroup, dealsGroup]
  )
  const MarketMakerQuantity = prop => {
    const { market_maker_quantity } = prop.data
    return (
      <TextQuantity quantity={market_maker_quantity}>
        {numberFormat(prop.data.market_maker_quantity)}
      </TextQuantity>
    )
  }

  const MyHeaderComponent = () => {
    const cleanCheckBox = () => {
      if (selected !== 0) {
        cleanCheckBoxState()
      }
    }

    return (
      <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <FormControlLabel
          control={
            <CheckboxStyle
              icon={<DeselectedCheckBox fontSize="small" />}
              checkedIcon={<DeselectedCheckBox fontSize="small" />}
            />
          }
          onClick={cleanCheckBox}
          label={`(${selected})`}
        />
        <Typography className="ag-header-cell-text" style={{ textAlign: 'center', width: '100%' }}>
          Id
        </Typography>
      </Box>
    )
  }

  const defaultColDef = {
    resizable: false,
    minWidth: 200,
    suppressMovable: true,
    cellClass: 'suppress-movable-col'
  }

  const columnsDefs = [
    {
      headerName: 'Id',
      field: 'id',
      headerComponent: MyHeaderComponent,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: params => {
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              className="deal-container"
            >
              <Status
                status={params.data.status}
                allocationStatus={params.data.allocation_status}
                stagingStatus={params.data.staging_status}
                isDeal={Array.isArray(params.data.trades)}
              />
              <CheckboxTradeBlotter
                checked={handelChecked(params.data)}
                onChange={() => handelChangeGroup(params.data)}
              />
              {params.data.id}
            </Box>
          )
        }
      }
    },
    { headerName: 'Type', field: 'type' },
    { headerName: 'Client', field: 'client' },
    { headerName: 'Market Maker', field: 'market_maker' },
    { headerName: 'Buy/Sell', field: 'side' },
    { headerName: 'Client Quantity', field: 'client_quantity', cellRenderer: ClientQuantity },
    {
      headerName: 'Market Maker Quantity',
      field: 'market_maker_quantity',
      cellRenderer: MarketMakerQuantity
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
      cellRenderer: CurrencyPairCell
    },
    {
      headerName: 'Client Price',
      field: 'client_price',
      valueFormatter: params => {
        return numberFormat(params.data.client_price, 6)
      }
    },
    {
      headerName: 'Market Maker Price',
      field: 'market_maker_price',
      valueFormatter: params => {
        return numberFormat(params.data.market_maker_price, 6)
      }
    },
    {
      headerName: 'P&L USD',
      field: 'pnl_usd',
      valueFormatter: params => {
        return numberFormat(params.data.pnl_usd)
      }
    },
    {
      headerName: 'Trade Date',
      field: 'trade_date',
      comparator: (valueA, valueB) => new Date(valueA).getTime() - new Date(valueB).getTime()
    },
    {
      headerName: 'Value Date',
      field: 'value_date',
      comparator: (valueA, valueB) => new Date(valueA).getTime() - new Date(valueB).getTime()
    },
    { headerName: 'Prime Broker', field: 'prime_broker' },
    { headerName: 'Direction', field: 'direction' }
  ]

  const onRowClicked = useCallback(
    params => {
      const id = Number(params.data.id)
      // if trade not selected
      if (!params.event.target.parentNode.querySelector('input')?.checked) {
        if (!Array.isArray(params.data.trades)) {
          const copyTradesGroup = JSON.parse(JSON.stringify(tradesGroup))
          copyTradesGroup[id] = params.data
          setSelected(selected + 1)
          setTradesGroup(copyTradesGroup)
        } else if (Array.isArray(params.data.trades) && !params.node.parent?.expanded) {
          const copyDealsGroup = JSON.parse(JSON.stringify(dealsGroup))
          const copyDealsGiveUpGroup = JSON.parse(JSON.stringify(dealsGiveUpGroup))
          copyDealsGroup[id] = params.data
          setSelected(selected + 1)
          if (params.data.staging_status === STAGING_STATUS.STAGED) {
            copyDealsGiveUpGroup[id] = params.data
            setDealsGiveUpGroup(copyDealsGiveUpGroup)
          }
          setDealsGroup(copyDealsGroup)
        }
      } else if (params.event.target.parentNode.querySelector('input')?.checked) {
        if (!Array.isArray(params.data.trades)) {
          const copyTradesGroup = JSON.parse(JSON.stringify(tradesGroup))
          delete copyTradesGroup[id]
          setSelected(selected - 1)
          setTradesGroup(copyTradesGroup)
        } else if (Array.isArray(params.data.trades) && !params.node.parent?.expanded) {
          const copyDealsGroup = JSON.parse(JSON.stringify(dealsGroup))
          const copyDealsGiveUpGroup = JSON.parse(JSON.stringify(dealsGiveUpGroup))
          delete copyDealsGroup[id]
          if (copyDealsGiveUpGroup[id]) {
            delete copyDealsGiveUpGroup[id]
            setDealsGiveUpGroup(copyDealsGiveUpGroup)
          }
          setSelected(selected - 1)
          setDealsGroup(copyDealsGroup)
        }
      }
    },
    [tradesGroup, dealsGroup, selected]
  )
  const handelChangeGroup = useCallback(
    params => {
      const tradesGroupCopy = JSON.parse(JSON.stringify(tradesGroup))
      const dealsGroupCopy = JSON.parse(JSON.stringify(dealsGroup))
      const dealsGiveUpGroupCopy = JSON.parse(JSON.stringify(dealsGiveUpGroup))

      const id = Number(params.id)
      if (Array.isArray(params.trades) && !dealsGroup[id]) {
        dealsGroupCopy[id] = params
        setDealsGroup(dealsGroupCopy)
        if (params.staging_status === STAGING_STATUS.STAGED) {
          dealsGiveUpGroupCopy[id] = params
          setDealsGiveUpGroup(dealsGiveUpGroupCopy)
        }
      } else if (!Array.isArray(params.trades) && tradesGroup[id]) {
        delete tradesGroupCopy[id]
        setTradesGroup(tradesGroupCopy)
      } else if (!Array.isArray(params.trades) && !tradesGroup[id]) {
        tradesGroupCopy[id] = params
        setTradesGroup(tradesGroupCopy)
      } else if (Array.isArray(params.trades) && dealsGroup[id]) {
        delete dealsGroupCopy[id]
        setDealsGroup(dealsGroupCopy)
        if (params.staging_status === STAGING_STATUS.STAGED) {
          delete dealsGiveUpGroupCopy[id]
          setDealsGiveUpGroup(dealsGiveUpGroupCopy)
        }
      }
    },
    [tradesGroup, dealsGroup]
  )

  const getRowStyle = useCallback(params => {
    if (
      params.data.status === TRADE_BLOTTER_STATUS.WARNING ||
      params.data.allocation_status === TRADE_BLOTTER_STATUS.UNALLOCATED ||
      params.data.staging_status === TRADE_BLOTTER_STATUS.STAGED
    ) {
      return { background: '#242432' }
    }
    if (params.data.status === TRADE_BLOTTER_STATUS.NEW) {
      params.data.status = TRADE_BLOTTER_STATUS.NEW_END
      return { background: '#30F0D626', animation: 'fadeOut ease-out 3s forwards' }
    }
    if (params.data.status === TRADE_BLOTTER_STATUS.UNION) {
      return { background: '#3a2f15' }
    }
  }, [])

  const onRowGroupOpened = params => {
    const node = params.node
    const id = Number(node.data.id)
    if (node.expanded) {
      setExpandedRows(current => {
        current[id] = true
        return current
      })
    } else {
      setExpandedRows(current => {
        delete current[id]
        return current
      })
    }
  }

  const getContextMenuItems = useCallback(params => {
    const showTrade =
      params.node && params.node.data.deal_id === null && params.node.data.lucera_trade_id !== null
    if (showTrade) {
      return [
        {
          ...{
            menuItem: () => (
              <MenuItem
                name={'Copy Trade'}
                onMenuClick={() => copyId(dispatch, params.node.data.lucera_trade_id, 'trade')}
                icon={<CopyComponentStyle />}
                disabled={false}
              />
            )
          }
        }
      ]
    }

    const showDownload = params.node && Array.isArray(params.node.data.trades) && params.node.master
    if (!showDownload) {
      return false
    }
    const disabledDownload = params.node.data.staging_status === STAGING_STATUS.STAGED

    const luceraTradesId = () => {
      if (Array.isArray(params.node.data.trades)) {
        return Array.from(
          new Set(params.node.data.trades.map(({ lucera_trade_id }) => lucera_trade_id))
        )
      } else {
        return params.node.data.lucera_deal_id
      }
    }
    if (showDownload) {
      return [
        {
          ...(showDownload
            ? {
                menuItem: () => (
                  <MenuItem
                    name={'Download Trade Report'}
                    onMenuClick={() =>
                      downlandExcelTrades(
                        dispatch,
                        token,
                        Number(params.node.data.id),
                        undefined,
                        `deal ${Number(params.node.data.id)}`
                      )
                    }
                    disabled={disabledDownload}
                    icon={<DownloadComponentStyle />}
                  />
                )
              }
            : [])
        },
        {
          ...{
            menuItem: () => (
              <MenuItem
                name={'Copy Deal'}
                onMenuClick={() => copyId(dispatch, luceraTradesId().join(','))}
                icon={<CopyComponentStyle />}
                disabled={
                  (Array.isArray(params.node.data.trades) && !params.node.data.lucera_deal) ||
                  (params.node.data.deal_id === null && !params.node.data.lucera_deal_id)
                }
              />
            )
          }
        }
      ]
    }
  }, [])

  const onBodyScroll = useCallback(
    params => {
      gridScrollHight.current.top = params.top
      if (pendingData.status !== null && gridScrollHight.current.top < 200 && lock === false) {
        setLock(true)
        setTradeBlotterResponse(pendingData)
        setPendingData(() => {
          setLock(false)
          return { status: null, data: [] }
        })
      }
    },
    [pendingData, tradeBlotterResponse, gridScrollHight, lock]
  )

  return {
    onRowClicked,
    onBodyScroll,
    columnsDefs,
    defaultColDef,
    isRowMaster,
    getRowId,
    getContextMenuItems,
    detailCellRenderer,
    getRowStyle,
    onRowGroupOpened
  }
}

export default useTradeBlotterAgGridSetting
