import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../hooks/useAxios'
import { MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants'
import axios from 'axios'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import Table from '../../../../components/Table/Table'
import { EditIconStyle } from '../../../../components/styled/global.style'
import Loader from '../../../../components/Loader/Loader'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice'

const Cuts = ({ params }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const request = { endPoint: routes.cut, params, modalType: MODAL_TYPE.CUTS }
  const { response, loading } = useAxios(request)

  const getCutById = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.cut, '/', id)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      dispatch(
        setModal({
          type: MODAL_TYPE.CUTS,
          title: MODAL_TITLE.EDIT_CUT,
          data: result.data,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const EditClient = prop => {
    return <EditIconStyle onClick={() => getCutById(prop.data.id)} />
  }

  const columns = [
    {
      headerName: 'Cut',
      field: 'cut'
    },
    {
      flex: 1,
      resizable: false
    },
    { headerName: 'Edit', cellRenderer: EditClient }
  ]
  if (loading === true) {
    return <Loader />
  }

  if (response.status === 200) {
    return <Table rows={response.data} columns={columns} />
  }
}

Cuts.propTypes = { params: PropTypes.object, setParams: PropTypes.func }

export default Cuts
