import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../../../components/Table/Table'
import { MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../hooks/useAxios'
import axios from 'axios'
import Loader from '../../../../components/Loader/Loader'
import { CircleIconStyle, EditIconStyle } from '../../../../components/styled/global.style'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice'

const User = ({ params }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const request = { endPoint: routes.user, params, modalType: MODAL_TYPE.USERS }
  const { response, loading } = useAxios(request)

  const getUserById = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.user, '/', id)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      dispatch(
        setModal({
          type: MODAL_TYPE.USERS,
          title: MODAL_TITLE.EDIT_USER,
          data: result.data,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const EditClient = prop => {
    return <EditIconStyle onClick={() => getUserById(prop.data.id)} />
  }
  const ActiveClient = prop => {
    return <CircleIconStyle isactive={String(prop.data.is_active)} />
  }

  const columns = [
    {
      headerName: 'First Name',
      field: 'first_name',
      flex: 1
    },
    { headerName: 'Last Name', field: 'last_name', flex: 1 },
    { headerName: 'Username', field: 'username', flex: 1 },
    { headerName: 'Email Address', field: 'email', flex: 1 },
    { headerName: 'Entitlements', field: 'entitlements', flex: 1 },
    { headerName: 'Active', cellRenderer: ActiveClient, flex: 1 },
    { headerName: 'Edit', cellRenderer: EditClient, flex: 1 }
  ]
  if (loading === true) {
    return <Loader />
  }

  if (response.status === 200) {
    return <Table rows={response.data} columns={columns} />
  }
}

User.propTypes = {
  params: PropTypes.object,
  setParams: PropTypes.func
}

export default User
