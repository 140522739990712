import React from 'react'
import { useSelector } from 'react-redux'
import PublicRoutes from './components/PublicRoutes/PublicRoutes'
import PrivateRoutes from './components/PrivateRoutes/PrivateRoutes'



const AppRouter = () => {
  const token = useSelector(state => state.auth.token)
  return <>{token ? <PrivateRoutes /> : <PublicRoutes />}</>
}

export default AppRouter
