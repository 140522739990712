import { Autocomplete, styled, TextField } from '@mui/material'
import closeImage from '../../../../assets/close.svg'
export const AutocompleteStyle = styled(Autocomplete)(({ theme, height = '50px' }) => {
  return {
    border: '1px solid #2c2c2e',
    borderRadius: '4px',
    padding: '0px',
    height,
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: theme.palette.separators.primary,
    '& .MuiFormControl-root': {
      display: 'inline'
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.button.save.primary
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: '#BABEC7'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent'
      },
      '&:hover fieldset': {
        borderColor: 'transparent' // Remove the hover border
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent' // Remove the focus border
      }
    },
    '& .MuiAutocomplete-popupIndicator': {
      '&:hover': {
        backgroundColor: 'transparent' // Remove hover background color for the indicator
      }
    },
    '& .MuiAutocomplete-root': {
      backgroundColor: 'red'
    },
    '& .MuiChip-root': {
      backgroundColor: theme.palette.modal.primary,
    },
    '& .MuiChip-root .MuiSvgIcon-root': {
      fill: 'transparent',
      marginTop: '4px',
      backgroundImage: `url(${closeImage})`,
      backgroundRepeat: 'no-repeat',
    }
  }
})

export const TextFieldStyle = styled(TextField)(() => {
  return {
    backgroundColor: '#39393C',
    overflowY: 'auto',
    borderRadius: '4px'
  }
})
