import { combineReducers } from '@reduxjs/toolkit'
import modalReducer from './slices/Modal/modalSlice'
import notificationReducer from './slices/Notification/notificationSlice'
import authReducer from './slices/Auth/AuthSlice'
const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  notification: notificationReducer
})

export default appReducer
