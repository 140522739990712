import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RedirectToLogin from '../RedirectToLogin/RedirectToLogin'
import UpdatePassword from '../../../../pages/UpdatePassword/UpdatePassword'
import ResetPassword from '../../../../pages/ResetPassword/ResetPassword'
import Login from '../../../../pages/Login/Login'
import EmailSent from '../../../../pages/EmailSent/EmailSent'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/change/password/:token" element={<UpdatePassword title="Change Password" />} />
      <Route path="/reset/password" element={<ResetPassword />} />
      <Route path="/sent/email" element={<EmailSent />} />
      <Route path="/new/password/:token" element={<UpdatePassword title="New Password" />} />
      <Route path="*" element={<RedirectToLogin />} />
    </Routes>
  )
}

export default PublicRoutes
