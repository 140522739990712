import React from 'react'
import { Box, Typography } from '@mui/material'
import CurrencyLogo from '../../components/CurrencyLogo/CurrencyLogo'
import FlagCurrency from '../../components/FlagCurrency/FlagCurrency'
import { TextQuantity } from '../TradeBlotter/TradeBlotter.style'
import { numberFormat } from '../../helper'

const CurrencyPairCell = prop => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        height: '100%'
      }}
    >
      <Typography>{prop.data.base_currency}</Typography>
      <FlagCurrency countryCode={prop.data.base_currency_country} />
      <CurrencyLogo currencyCode={prop.data.base_currency} />
      <Typography>/</Typography>
      <Typography>{prop.data.counter_currency}</Typography>
      <FlagCurrency countryCode={prop.data.counter_currency_country} />
      <CurrencyLogo currencyCode={prop.data.counter_currency} />
    </Box>
  )
}

const ClientQuantity = prop => {
  const { quantity } = prop.data
  return <TextQuantity quantity={quantity}>{prop.data.quantity}</TextQuantity>
}

export const columnsDefs = [
  {
    headerName: 'Id',
    field: 'id'
  },
  {
    headerName: 'Type',
    field: 'type'
  },
  { headerName: 'CounterParty', field: 'counterparty' },
  { headerName: 'Buy/Sell', field: 'side' },
  {
    headerName: 'Quantity',
    field: 'quantity',
    cellRenderer: ClientQuantity,
    valueFormatter: params => {
      return numberFormat(params.data.quantity)
    }
  },
  {
    headerName: 'Symbol',
    field: 'symbol',
    cellRenderer: CurrencyPairCell
  },
  {
    headerName: 'Price',
    field: 'price',
    valueFormatter: params => {
      return numberFormat(params.data.price)
    }
  },
  {
    headerName: 'Trade Date',
    field: 'trade_date',
    comparator: (valueA, valueB) => new Date(valueA).getTime() - new Date(valueB).getTime()
  },
  {
    headerName: 'Value Date',
    field: 'value_date',
    comparator: (valueA, valueB) => new Date(valueA).getTime() - new Date(valueB).getTime()
  },
  { headerName: 'Prime Broker', field: 'prime_broker' },
  { headerName: 'Direction', field: 'direction' }
]

export const defaultColDef = {
  flex: 1
}
