import React from 'react'
import Stack from '@mui/material/Stack'
import { useSelector } from 'react-redux'
import { SnackbarContentStyled } from './Notifications.style'

function Notifications() {
  const notifications = useSelector(state => state.notification.notifications)
  return (
    <Stack spacing={2} sx={{ position: 'absolute', right: 10, top: 10 }}>
      {notifications.map(({ message, id, type }) => (
        <SnackbarContentStyled key={id} message={message.toLowerCase()} type={type} />
      ))}
    </Stack>
  )
}

export default Notifications
