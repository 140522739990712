import { createSlice } from '@reduxjs/toolkit'
import { handelNotifications } from '../Notification/notificationSlice'
import { routes } from '../../../utils/constants'
import axios from 'axios'
import { webSocketWorkerEvent,webSocketWorkerPriceEvent } from '../../../services/index'

const initialState = {
  entitlements: [],
  email: '',
  token: sessionStorage.getItem('token') ?? '',
  username: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.entitlements = action.payload.entitlements
      state.token = `Bearer ${action.payload.token}`
      state.username = action.payload.username
      state.email = action.payload.email
      sessionStorage.setItem('token', state.token)
    },
    clearAuth: state => {
      sessionStorage.clear()
      for (const [key, value] of Object.entries(initialState)) {
        state[key] = value
      }
    }
  }
})

export const resetAuthByEmail = (form, navigate) => async dispatch => {
  const url = process.env.REACT_APP_SERVER_URL.concat(routes.resetPasswordEmail)
  try {
    await axios.put(url, form)
    navigate('/email/sent')
  } catch (error) {
    if (Array.isArray(error?.response?.data)) {
      for (const { message } of error.response.data) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: message
          })
        )
      }
    }
  }
}

export const resetNewPassword = (form, token, navigate) => async dispatch => {
  const url = process.env.REACT_APP_SERVER_URL.concat(routes.resetPassword)
  try {
    const data = { ...form, token }
    const result = await axios.put(url, data)
    dispatch(setAuth(result.data))
    webSocketWorkerEvent.connectWs(result.data.token)
   
    navigate('/trade/blotter')
  } catch (error) {
    if (Array.isArray(error?.response?.data)) {
      for (const { message } of error.response.data) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: message
          })
        )
      }
    }
  }
}

export const loginUser = (form, navigate) => async dispatch => {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}${routes.auth}`
    const result = await axios.post(url, form)
    if (result.data.token && !('message' in result.data)) {
      dispatch(setAuth(result.data))
      navigate('/trade/blotter')
      webSocketWorkerEvent.connectWS(`${result.data.token}`)
      webSocketWorkerPriceEvent.connectPricerWS()
    } else {
      navigate(`/change/password/${result.data.token}`)
    }
  } catch (error) {
    if (Array.isArray(error?.response?.data))
      for (const { message } of error.response.data) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: message
          })
        )
      }
  }
}

export const userLogout = navigate => dispatch => {
  dispatch(clearAuth())
  navigate('/login')
  webSocketWorkerEvent.closeWS()
}

export const automaticLogin = (navigate, pathname) => async (dispatch, getState) => {
  try {
    const token = getState().auth.token
    const url = `${process.env.REACT_APP_SERVER_URL}${routes.authToken}`
    await axios.get(url, { headers: { Authorization: token } })
    const wsToken = token.substring('Bearer '.length)
    webSocketWorkerEvent.connectWS(wsToken)
    navigate(pathname)
  } catch (error) {
    dispatch(userLogout(navigate))
    console.log(error.message)
  }
}

export const { setAuth, clearAuth } = authSlice.actions

export default authSlice.reducer
