import styled from '@emotion/styled'
import { Typography, Stack } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import CheckIcon from '@mui/icons-material/Check'

export const TextRule = styled(Typography)(({ theme }) => ({
  color: theme.palette.svg.clearIcon,
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Nunito',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}))

export const ContainerRule = styled(Stack)(({ theme }) => ({
  borderRadius: '4px',
  gap: '14px',
  paddingLeft: '24px',
  paddingBottom: '12px',
  paddingTop: '12px',
  paddingRight: '16px',
  backgroundColor: theme.palette.tabs.backgroundColor.hover
}))

export const IconListRule = styled(CircleIcon)(({ theme }) => ({
  width: '4px',
  height: '4px',
  marginTop: '5px',
  marginRight: '8px',
  color: theme.palette.button.save.primary
}))

export const IconCheck = styled(CheckIcon)(({ theme, show }) => {
  let display = 'none'
  if (show === 'true') {
    display = 'block'
  }
  return {
    display,
    width: '16px',
    height: '16px',
    color: theme.palette.switch.active
  }
})
