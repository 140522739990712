import React, { Suspense, lazy } from 'react'
import { styled } from '@mui/system'
import { Modal as BaseModal } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from '../../redux/slices/Modal/modalSlice'
import { MODAL_TYPE } from '../../utils/constants'
import ModalHeader from './components/ModalHeader/ModalHeader'
import { ModalContent } from './ModalApp.style'
import Loader from '../Loader/Loader'
import Deal from './children/Deal/Deal'
const FixingSource = lazy(() => import('./children/FixingSource/FixingSource'))
const MarketMakers = lazy(() => import('./children/MarketMakers/MarketMakers'))
const Cuts = lazy(() => import('./children/Cuts/Cuts'))
const CurrencyPair = lazy(() => import('./children/CurrencyPair/CurrencyPair'))
const PrimeBroker = lazy(() => import('./children/PrimeBroker/PrimeBroker'))
const User = lazy(() => import('./children/User/User'))
const Client = lazy(() => import('./children/Client/Client'))
const Trade = lazy(() => import('./children/Trade/Trade'))
const AllocateBlock = lazy(() => import('./children/AllocateBlock/AllocateBlock'))

function ModalApp() {
  const modal = useSelector(state => state.modal)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeModal())
  }

  const modalBody = () => {
    switch (modal.type) {
      case MODAL_TYPE.CLIENT:
        return <Client data={modal.data} />
      case MODAL_TYPE.MARKET_MAKERS:
        return <MarketMakers data={modal.data} />
      case MODAL_TYPE.PRIME_BROKER:
        return <PrimeBroker data={modal.data} />
      case MODAL_TYPE.FIXING_SOURCE:
        return <FixingSource data={modal.data} />
      case MODAL_TYPE.CUTS:
        return <Cuts data={modal.data} />
      case MODAL_TYPE.CURRENCY_PAIRS:
        return <CurrencyPair data={modal.data} />
      case MODAL_TYPE.USERS:
        return <User data={modal.data} />
      case MODAL_TYPE.TRADE:
        return <Trade data={modal.data} />
      case MODAL_TYPE.DEAL:
        return <Deal data={modal.data} />
      case MODAL_TYPE.ALLOCATE_BLOCK:
        return <AllocateBlock data={modal.data} />
      default:
        return <></>
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <Modal open={modal.isOpen} onClose={handleClose}>
        <ModalContent>
          <ModalHeader title={modal.title} />
          {modalBody()}
        </ModalContent>
      </Modal>
    </Suspense>
  )
}

const Modal = styled(BaseModal)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.modal.primary,
  '&.base-Modal-hidden': {
    visibility: 'hidden'
  },
  '& > .MuiBackdrop-root': {
    backdropFilter: 'blur(0.8px)'
  },
  [theme.breakpoints.between('xs', 'lg')]: {
    '& .MuiPaper-root': {
      margin: 0,
      overflowX: 'auto',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      borderRadius: 0
    }
  }
}))

export default ModalApp
