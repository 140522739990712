import React from 'react'
import PropTypes from 'prop-types'
import Flag from 'react-world-flags'
import { ReactComponent as EurFlag } from '../../assets/eur.svg'

const FlagCurrency = ({ countryCode }) => {
  if (countryCode === 'EUR') {
    return <EurFlag style={{ borderRadius: '3px', height: '16px', width: '22px' }} />
  } else {
    return (
      <Flag code={countryCode} style={{ borderRadius: '3px', height: '16px', width: '22px' }} />
    )
  }
}

FlagCurrency.propTypes = {
  countryCode: PropTypes.string
}

export default FlagCurrency
