import React, { useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import colorTheme from './utils/theme'
import Header from './components/Header/Header'
import Aside from './components/Aside/Aside'
import AppRouter from './components/AppRouter/AppRouter'
import ModalApp from './components/ModalApp/ModalApp'
import Notifications from './components/Notifications/Notifications'
import { useLocation } from 'react-router-dom'
import { LicenseManager } from 'ag-grid-enterprise'
import { automaticLogin } from './redux/slices/Auth/AuthSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom/dist'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE)

function App() {
  const location = useLocation().pathname
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    dispatch(automaticLogin(navigate, pathname))
  }, [location])

  const theme = createTheme({
    palette: colorTheme
  })

  const renderMainContent = () => {
    if (
      ['/login', '/reset/password', '/change/password', '/new/password', '/email/sent'].some(p =>
        pathname.startsWith(p)
      )
    ) {
      return (
        <main
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <AppRouter />
          <Notifications />
        </main>
      )
    } else {
      return (
        <>
          <Aside />
          <header style={{ marginLeft: '2rem' }}>
            <Header />
          </header>
          <main style={{ marginLeft: '5rem' }}>
            <AppRouter />
            <ModalApp />
            <Notifications />
          </main>
        </>
      )
    }
  }

  return <ThemeProvider theme={theme}>{renderMainContent()}</ThemeProvider>
}

export default App
