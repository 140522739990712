import SnackbarContent from '@mui/material/SnackbarContent'
import { styled } from '@mui/system'

export const SnackbarContentStyled = styled(SnackbarContent)(({ type, theme }) => {
  let backgroundColor = theme.palette.notification.success
  switch (type) {
    case 'error':
      backgroundColor = theme.palette.notification.error
      break
    case 'success':
      backgroundColor = theme.palette.notification.success
      break
    default:
  }
  return {
    color: 'white',
    fontSize: '13px',
    backgroundColor,
    width: 'fit-content',
    textTransform: 'capitalize'
  }
})
