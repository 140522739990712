import { Box, styled } from '@mui/material'
import { ReactComponent as DownloadIcon } from '../../assets/download_trade_file_page.svg'
import { ReactComponent as DownloadIconSelect } from '../../assets/download_trade_file_page_select.svg'

export const DownloadIconSelectStyle = styled(DownloadIcon)(({ theme }) => ({
  marginTop: '10px'
}))

export const DownloadIconSelectHover = styled(DownloadIconSelect)(({ theme }) => ({
  marginTop: '10px',
  cursor: 'pointer'
}))

export const DealExcelBlotterTool = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '10px'
}))
