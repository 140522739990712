import { Box, CardContent } from '@mui/material'
import React from 'react'
import { CardText, CardTitle, MyCard } from '../../components/styled/global.style'
import { EmailSentIconStyle } from './EmailSent.style'

const EmailSent = () => {

  return (
    <MyCard>
      <CardContent>
        <Box style={{ width: '100%', textAlign: 'center', marginBottom: '32px' }}>
          <EmailSentIconStyle />
        </Box>
        <CardTitle style={{ marginBottom: '24px' }}>Email Sent!</CardTitle>
        <CardText style={{ marginBottom: '24px' }}>
          Please check your email for the confirmation link.
        </CardText>
      </CardContent>
    </MyCard>
  )
}

export default EmailSent
