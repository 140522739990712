import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../../../../pages/Login/Login'
import TradeBlotter from '../../../../pages/TradeBlotter/TradeBlotter'
import routers from '../../../../utils/router'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {routers.map((route, index) => {
        const { path, element } = route
        return <Route key={index} path={path} element={element} />
      })}
      <Route path="*" element={<TradeBlotter />} />
    </Routes>
  )
}

export default PrivateRoutes
