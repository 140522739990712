import { styled } from '@mui/system'
import { Box, Tab, Tabs } from '@mui/material'

export const TabStyle = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '16px 32px',
  background: 'black',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    color: theme.palette.text.primary
  }
}))

export const TabsContainerStyle = styled(Tabs)(({ theme }) => ({
  background: theme.palette.tabs.backgroundColor.primary,
  width: 'fit-content',
  color: theme.palette.text.primary,
  '& .MuiTabs-root': {
    width: 'fit-content'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: `${theme.palette.button.save.primary} !important`
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.tabs.arrow.primary
  }
}))

export const GridWarper = styled(Box)({
  display: 'flex'
})

export const StaticsContainer = styled(Box)({
  display: 'flex',
  marginTop: '8px',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
})

export const StaticsItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',

  [theme.breakpoints.between('xs', 'xl')]: {
    marginTop: '8px'
  }
}))
