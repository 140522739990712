import { useGridMenuItem } from 'ag-grid-react/lib/main'
import React from 'react'
import PropTypes from 'prop-types'
import { CopyDownloadMenuContainer, MenuItemStyle, TextStyle } from './MenuItem.style'
import { Box } from '@mui/material'

const MenuItem = ({ name, icon, onMenuClick, disabled }) => {
  useGridMenuItem({
    configureDefaults: () => false
  })
  return (
    <CopyDownloadMenuContainer className="deal-container">
      <MenuItemStyle onClick={onMenuClick} disabled={disabled}>
        <Box>{icon}</Box>
        <TextStyle>{name}</TextStyle>
      </MenuItemStyle>
    </CopyDownloadMenuContainer>
  )
}

export default MenuItem

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onMenuClick: PropTypes.func,
  name: PropTypes.string
}
