import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants'
import useAxios from '../../../../hooks/useAxios'
import axios from 'axios'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import {
  EditIconStyle,
  CheckIconStyle,
  CircleIconStyle
} from '../../../../components/styled/global.style'
import Table from '../../../../components/Table/Table'
import Loader from '../../../../components/Loader/Loader'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice'

const MarketMaker = ({ params }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const request = { endPoint: routes.marketMaker, params, modalType: MODAL_TYPE.MARKET_MAKERS }
  const { response, loading } = useAxios(request)

  const getMarketMakerById = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker, '/', id)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      dispatch(
        setModal({
          type: MODAL_TYPE.MARKET_MAKERS,
          title: MODAL_TITLE.EDIT_MARKET_MAKER,
          data: result.data,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const EditMarketMaker = prop => {
    return <EditIconStyle onClick={() => getMarketMakerById(prop.data.id)} />
  }

  const ActiveClient = prop => {
    return <CircleIconStyle isactive={String(prop.data.is_active)} />
  }
  const SwapDealerCell = prop => <CheckIconStyle isactive={prop.data.swap_dealer} />

  const BookingCell = prop => <CheckIconStyle isactive={prop.data.booking_confirmation} />
  const PBACell = prop => <CheckIconStyle isactive={prop.data.pba} />

  const columns = [
    {
      headerName: 'Name',
      field: 'market_maker_name',
      flex: 1
    },
    { headerName: 'Market Maker PB', field: 'prime_broker', flex: 1 },
    { headerName: 'PB Mapping', field: 'prime_broker_mapping', flex: 1 },
    { headerName: 'Market Maker Country', field: 'country', flex: 1 },
    { headerName: 'LEI', field: 'lei', flex: 1 },
    { headerName: 'Lucera Drop Value', field: 'lucera_drop_value', flex: 1 },
    { headerName: 'Products', field: 'products', flex: 1 },
    { headerName: 'Swap', cellRenderer: SwapDealerCell, flex: 1 },
    { headerName: 'Booking Confirmation', cellRenderer: BookingCell, flex: 1 },
    { headerName: 'PBA', cellRenderer: PBACell, flex: 1 },
    {
      headerName: 'Client Contact Distribution List',
      field: 'contract_distribution_list',
      flex: 1
    },
    { headerName: 'Makor PB', field: 'makor_prime_broker', flex: 1 },
    { headerName: 'Makor Company', field: 'makor_company', flex: 1 },
    { headerName: 'Staging Status', field: 'staging_status', flex: 1 },
    { headerName: 'Active', cellRenderer: ActiveClient, flex: 1 },
    { headerName: 'Edit', cellRenderer: EditMarketMaker }
  ]
  if (loading === true) {
    return <Loader />
  }

  if (response.status === 200) {
    return <Table rows={response.data} columns={columns} />
  }
}

MarketMaker.propTypes = { params: PropTypes.object, setParams: PropTypes.func }

export default MarketMaker
