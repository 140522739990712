import React, { useEffect, useRef, useState } from 'react'
import { PnlDailyContainer } from './DailyPnl.style'
import { webSocketWorkerEvent, webSocketWorkerPriceEvent } from '../../../../services'
import currencyList from '../../../../constants/currencyList'
import { createBloombergCurrency } from '../../../../helper'

const DailyPnl = () => {
  const [dealsPnl, setDealsPnlState] = useState([])
  const [pnl, setPnl] = useState('...')
  const productsPrice = useRef({})
  useEffect(() => {
    webSocketWorkerPriceEvent.connectPricerWS()
  }, [])

  const initialBloombergCurrency = () => {
    const baseCurrencies = currencyList.map(({ base_currency }) => base_currency)
    const condition = dealsPnl.every(({ counter_currency }) =>
      baseCurrencies.includes(counter_currency)
    )
    if (condition) {
      return currencyList
    } else {
      const currencyBloombergToAdd = dealsPnl
        .filter(({ counter_currency }) => baseCurrencies.includes(counter_currency))
        .map(({ counter_currency, counter_currency_country }) => {
          return JSON.stringify({
            counter_currency,
            counter_currency_country
          })
        })
      const getUniqueCurrencyBloomberg = Array.from(new Set(currencyBloombergToAdd)).map(deal =>
        createBloombergCurrency(JSON.parse(deal))
      )
      return currencyList.concat(getUniqueCurrencyBloomberg)
    }
  }

  useEffect(() => {
    if (dealsPnl.length) {
      const bloombergCurrency = initialBloombergCurrency()
      webSocketWorkerPriceEvent.sendEvent(bloombergCurrency)
    }
  }, [dealsPnl])

  useEffect(() => {
    webSocketWorkerEvent.sendEvent({
      type: 'get_daily_pnl',
      data: {}
    })
  }, [])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message.data.type === 'get_daily_pnl' &&
        message.data.status === 'ok' &&
        message.data.data
      ) {
        const { data } = message.data
        setDealsPnlState(data)
      }
    }
    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message.data &&
        message.data.action === 'new' &&
        message.data.base_currency &&
        message.data.counter_currency &&
        message.data.LAST_PRICE
      ) {
        const { base_currency, counter_currency } = message.data
        if (!productsPrice.current[base_currency] && counter_currency === 'USD') {
          productsPrice.current[base_currency] = { price: message.data.LAST_PRICE }
        } else {
          productsPrice.current[base_currency].price = message.data.LAST_PRICE
        }
        const baseCurrency = {}
        let allDealIn = true
       
        for (const { counter_currency } of dealsPnl) {
          if (counter_currency === 'USD') {
            continue
          }
          if (counter_currency !== 'USD') {
            baseCurrency[counter_currency] = 0
          }
          if (productsPrice.current[counter_currency]) {
            baseCurrency[counter_currency] = 1
          } else {
            allDealIn = true
          }
        }
        let totalPnl = 0
        if (allDealIn) {
          for (const deal of dealsPnl) {
            const { counter_currency, total_price } = deal
            if (counter_currency === 'USD') {
              totalPnl += total_price
            } else {
              totalPnl += total_price * productsPrice.current[counter_currency]?.price
              if (isNaN(totalPnl)) {
                return
              }
            }
          }
          setPnl(totalPnl)
        }
      }
    }

    webSocketWorkerPriceEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerPriceEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerPriceEvent, dealsPnl])

  return <PnlDailyContainer>Total P&L {pnl} </PnlDailyContainer>
}

export default DailyPnl
