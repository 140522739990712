import { styled } from '@mui/system'
import { Box, Typography, MenuItem } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'

export const HeaderWarper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 54px)', // Adjust for the drawer width
  marginLeft: '50px',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid var(--Separators, ${theme.palette.separators.primary})`
}))

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  leadingTrim: 'both',
  padding: '12px 16px 12px 24px',
  fontFamily: 'Nunito',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'capitalize'
}))

export const UserIcon = styled(AccountCircleIcon)(({ theme }) => ({
  fill: theme.palette.text.secondary,
  height: '32px',
  width: '32px',
  padding: '12px 16px 12px 24px'
}))

export const StyleLogoutIcon = styled(LogoutIcon)(({ theme }) => ({
  color: theme.palette.button.delete.primary
}))

export const StyleMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingRight: '42px',
  color: theme.palette.button.delete.primary
}))
