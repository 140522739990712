import { Box, MenuItem, Typography } from '@mui/material'
import styled from '@emotion/styled'

export const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '0px',
  paddingLeft: '15px',
  paddingRight: '15px',
  '&:hover': {
    background: theme.palette.separators.primary
  }
}))

export const CopyDownloadMenuContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center'
})

export const TextStyle = styled(Typography)({
  fontSize: 15,
  fontFamily: 'Nunito',
  textTransform: 'capitalize'
})
