import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FilterIconTableStyle, StackStyle, TextFieldStyle } from './FilterTable.style'
import { IconButtonStyle, StyledMenu } from '../../TradeBlotter.style'
import SearchTextField from '../../../../components/SearchTextField/SearchTextField'
import { Label } from '../../../../components/styled/global.style'
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox'
import { routes } from '../../../../utils/constants'
import useAxios from '../../../../hooks/useAxios'
import { checkSomeLoader, cleanEmptyField } from '../../../../helper'
import Loader from '../../../../components/Loader/Loader'
import { webSocketWorkerEvent } from '../../../../services'
import dayjs from 'dayjs'

let loaderFirstTime = false

const FilterTable = ({ filters, setFilters, cleanCheckBoxState, WSMessageType, setPage, page }) => {
  useEffect(() => {
    return () => {
      loaderFirstTime = false
    }
  }, [])

  const FILTERS_NAME = useMemo(
    () => ({
      ID: 'id',
      TRADE_DATE_START: 'trade_date_start',
      TRADE_DATE_END: 'trade_date_end',
      VALUE_DATE_START: 'value_date_start',
      VALUE_DATE_END: 'value_date_end',
      SYMBOLS: 'symbols',
      TYPES: 'types',
      COUNTERPARTY: 'counterparty',
      PRIME_BROKER: 'prime_broker',
      ORIGIN: 'origin'
    }),
    []
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const request1 = { endPoint: routes.tradeSymbol }
  const request2 = { endPoint: routes.tradeDealType }
  const request3 = { endPoint: routes.tradeCounterparty }
  const request4 = { endPoint: routes.primeBroker }
  const request5 = { endPoint: routes.tradeOrigin }
  const { response: tradeSymbolData, loader: loaderTradeSymbol } = useAxios(request1)
  const { response: tradeDealTypeData, loader: loaderTradeDealType } = useAxios(request2)
  const { response: tradeCounterpartyData, loader: loaderCounterParty } = useAxios(request3)
  const { response: primeBrokerData, loader: loaderPrimeBroker } = useAxios(request4)
  const { response: tradeOriginData, loader: tradeOriginLoader } = useAxios(request5)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handelFilters = (event, element) => {
    const { name, value } = event.target
    const copyFilters = JSON.parse(JSON.stringify(filters))
    if (
      element &&
      [
        FILTERS_NAME.COUNTERPARTY,
        FILTERS_NAME.SYMBOLS,
        FILTERS_NAME.TYPES,
        FILTERS_NAME.PRIME_BROKER,
        FILTERS_NAME.ORIGIN
      ].includes(name)
    ) {
      copyFilters[name] = element
      setFilters(copyFilters)
    } else if (FILTERS_NAME.ID) {
      copyFilters[name] = value
      setFilters(copyFilters)
    } else if (
      [
        FILTERS_NAME.TRADE_DATE_START,
        FILTERS_NAME.TRADE_DATE_END,
        FILTERS_NAME.VALUE_DATE_START,
        FILTERS_NAME.VALUE_DATE_END
      ].includes(name)
    ) {
      copyFilters[name] = value
      setFilters(copyFilters)
    }
    if (!isNaN(filters.offset) && filters.offset !== 0) {
      filters.offset = 0
    }
    if (page !== 1) {
      setPage(1)
    }
    cleanEmptyField(copyFilters)
    if (validationFilters(copyFilters)) {
      cleanCheckBoxState()
      webSocketWorkerEvent.sendEvent({ type: WSMessageType, data: copyFilters })
    }
  }

  const loaderList = [
    loaderCounterParty,
    loaderPrimeBroker,
    loaderTradeDealType,
    tradeOriginLoader,
    loaderTradeSymbol
  ]

  if (checkSomeLoader(loaderList) && !loaderFirstTime) {
    loaderFirstTime = true
    return <Loader />
  }

  const errorValueDate = () => {
    if (filters.value_date_start !== undefined) {
      if (dayjs(filters.value_date_start).isAfter(filters.value_date_end)) {
        return true
      }
    }
  }

  const errorTradeDate = () => {
    if (filters.trade_date_start !== undefined) {
      if (dayjs(filters.trade_date_start).isAfter(filters.trade_date_end)) {
        return true
      }
    }
  }

  const validationFilters = copyFilters => {
    if (copyFilters.value_date_start !== undefined) {
      if (dayjs(copyFilters.value_date_start).isAfter(copyFilters.value_date_end)) {
        return false
      }
    }
    if (copyFilters.trade_date_start !== undefined) {
      if (dayjs(copyFilters.trade_date_start).isAfter(copyFilters.trade_date_end)) {
        return false
      }
    }
    return true
  }

  return (
    <>
      <IconButtonStyle press={String(open)}>
        <FilterIconTableStyle aria-expanded={open ? 'true' : undefined} onClick={handleClick} />
      </IconButtonStyle>
      <StyledMenu
        style={{
          padding: '20px',
          display: 'flex',
          gap: '20px',
          flexDirection: 'column'
        }}
        anchorEl={anchorEl}
        aria-expanded={open ? 'true' : undefined}
        open={open}
        onClose={handleClose}
      >
        <StackStyle>
          <Label>ID</Label>
          <SearchTextField name={FILTERS_NAME.ID} handleChange={handelFilters} state={filters.id} />
        </StackStyle>
        <StackStyle>
          <Label>Trade Date Start</Label>
          <TextFieldStyle
            type="date"
            variant="outlined"
            value={filters.trade_date_start}
            name={FILTERS_NAME.TRADE_DATE_START}
            myerror={String(errorTradeDate())}
            style={{ background: 'black !important' }}
            onChange={handelFilters}
          />
        </StackStyle>
        <StackStyle>
          <Label>Trade Date End</Label>
          <TextFieldStyle
            type="date"
            variant="outlined"
            value={filters.trade_date_end}
            onChange={handelFilters}
            name={FILTERS_NAME.TRADE_DATE_END}
          />
        </StackStyle>
        <StackStyle>
          <Label>Value Date Start</Label>
          <TextFieldStyle
            type="date"
            variant="outlined"
            value={filters.value_date_start}
            name={FILTERS_NAME.VALUE_DATE_START}
            myerror={String(errorValueDate())}
            onChange={handelFilters}
          />
        </StackStyle>
        <StackStyle>
          <Label>Value Date End</Label>
          <TextFieldStyle
            type="date"
            variant="outlined"
            value={filters.value_date_end}
            onChange={handelFilters}
            name={FILTERS_NAME.VALUE_DATE_END}
          />
        </StackStyle>
        {Array.isArray(tradeDealTypeData.data) && (
          <StackStyle>
            <Label>Type</Label>
            <AutoCompleteCheckBox
              filters={filters}
              handelChange={handelFilters}
              name={FILTERS_NAME.TYPES}
              data={tradeDealTypeData.data.map(({ name }) => name)}
            />
          </StackStyle>
        )}

        {Array.isArray(tradeSymbolData.data) && (
          <StackStyle>
            <Label>Symbol</Label>
            <AutoCompleteCheckBox
              filters={filters}
              handelChange={handelFilters}
              name={FILTERS_NAME.SYMBOLS}
              data={tradeSymbolData.data.map(({ symbol }) => symbol)}
            />
          </StackStyle>
        )}

        {Array.isArray(primeBrokerData.data) && (
          <StackStyle>
            <Label>Prime Broker</Label>
            <AutoCompleteCheckBox
              handelChange={handelFilters}
              name={FILTERS_NAME.PRIME_BROKER}
              data={primeBrokerData.data.map(({ name }) => name)}
              filters={filters}
            />
          </StackStyle>
        )}

        {Array.isArray(tradeCounterpartyData.data) && (
          <StackStyle>
            <Label>Counterparty</Label>
            <AutoCompleteCheckBox
              handelChange={handelFilters}
              name={FILTERS_NAME.COUNTERPARTY}
              data={tradeCounterpartyData.data.map(({ name }) => name)}
              filters={filters}
            />
          </StackStyle>
        )}
        {Array.isArray(tradeOriginData.data) && (
          <StackStyle>
            <Label>Origin</Label>
            <AutoCompleteCheckBox
              handelChange={handelFilters}
              name={FILTERS_NAME.ORIGIN}
              data={tradeOriginData.data.map(({ name }) => name)}
              filters={filters}
            />
          </StackStyle>
        )}
      </StyledMenu>
    </>
  )
}

export default FilterTable

FilterTable.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  WSMessageType: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  cleanCheckBoxState: PropTypes.func
}
