import React, { useEffect, useState } from 'react'
import {
  CancelButton,
  CardText,
  CardTitle,
  MyCard,
  SaveChangesButton
} from '../../components/styled/global.style'
import { Box, CardContent, Grid } from '@mui/material'
import { ContainerRule, IconCheck } from './UpdatePassword.style'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import InputPassword from '../../components/InputPassword/InputPassword'
import { FromLabel } from '../Login/Login.style'
import { rules } from './data'
import { hasNumber, hasSymbol } from '../../helper'
import { useDispatch } from 'react-redux'
import { resetNewPassword } from '../../redux/slices/Auth/AuthSlice'

const UpdatePassword = ({ title }) => {
  const [form, setForm] = useState({ new_password: '', confirm_password: '' })
  const navigate = useNavigate()
  const [displays, setDisplays] = useState(new Array(3).fill(false))
  const params = useParams()
  const { token } = params
  const dispatch = useDispatch()

  useEffect(() => {
    validateForm()
  }, [form])

  const handelBackButton = () => {
    navigate('/login')
  }

  const handelSubmit = () => {
    dispatch(resetNewPassword(form, token, navigate))
  }

  const disabledButton = () => {
    return displays.some(display => display === false)
  }

  const handelChange = event => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const validateForm = () => {
    const copyDisplays = new Array(3).fill(false)
    if (form.new_password === form.confirm_password) {
      if (form.confirm_password.length >= 8) {
        copyDisplays[0] = true
      }
      if (hasNumber(form.confirm_password)) {
        copyDisplays[1] = true
      }
      if (hasSymbol(form.confirm_password)) {
        copyDisplays[2] = true
      }
    }
    setDisplays(copyDisplays)
  }

  return (
    <MyCard>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <Grid container spacing={2} style={{ marginTop: '40px', marginBottom: '32px' }}>
          <Grid item xs={6}>
            <Box>
              <Box style={{ marginBottom: '32px' }}>
                <FromLabel>New Password</FromLabel>
                <InputPassword
                  name="new_password"
                  formState={form.password}
                  handelChange={handelChange}
                />
              </Box>
              <Box>
                <FromLabel>Confirm Password</FromLabel>
                <InputPassword
                  name="confirm_password"
                  formState={form.confirmPassword}
                  handelChange={handelChange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <ContainerRule>
              <CardText>Must contain at least:</CardText>
              {rules.map((rule, index) => (
                <Box
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    marginBottom: '8px',
                    justifyContent: 'space-between'
                  }}
                >
                  {rule}
                  <IconCheck show={String(displays[index])} />
                </Box>
              ))}
            </ContainerRule>
          </Grid>
        </Grid>
      </CardContent>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '45px'
        }}
      >
        <CancelButton variant="contained" onClick={handelBackButton}>
          Cancel
        </CancelButton>
        <SaveChangesButton variant="contained" onClick={handelSubmit} disabled={disabledButton()}>
          Save Changes
        </SaveChangesButton>
      </Box>
    </MyCard>
  )
}

UpdatePassword.propTypes = {
  title: PropTypes.string.isRequired
}

export default UpdatePassword
