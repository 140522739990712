import * as React from 'react'
import PropTypes from 'prop-types'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import './DateTimePicker.css'
import { DateTimePickerStyle } from './DateTimePicker.style'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'

dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

export default function BasicDateTimePicker({
  handelDateChange,
  name,
  disabled = false,
  format,
  value
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePickerStyle
          format={format}
          disableHighlightToday={true}
          disabled={disabled}
          value={value}
          onChange={time => {
            handelDateChange(name, dayjs(time).format(format))
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}

BasicDateTimePicker.propTypes = {
  handelDateChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  value: PropTypes.string
}
