const palette = {
  text: {
    primary: '#FFFFFF',
    secondary: '#707079'
  },
  separators: {
    primary: '#39393C'
  },
  modal: {
    primary: '#242428'
  },
  switch: {
    active: '#2BDF68',
    unactive: '#707079'
  },
  svg: {
    clearIcon: '#BABEC7',
    hover: '#A8ADB7'
  },
  button: {
    text: {
      primary: '#A4A4FF'
    },
    addNew: {
      primary: '#30F0D6',
      hover: '#83f6e6'
    },
    save: {
      primary: '#9999FF',
      hover: '#b8b8ff'
    },
    cancel: {
      primary: '#E6E6E6',
      hover: '#eeeeee'
    },
    delete: {
      primary: '#FF7272',
      hover: '#FF9C9C'
    },
    allocate: {
      primary: '#39394E',
      hover: '#6B6B8A'
    },
    giveUp: {
      hover: '#1b5244',
      text: '#00FEA9'
    },
    disable: '#707079',
    edit: {
      primary: '#FF9747',
      hover: '#FFBF8E'
    }
  },
  pnlDaily: {},
  tabs: {
    arrow: {
      primary: '#FFFFFF'
    },
    borderBottom: '#60609f',
    backgroundColor: {
      primary: '#000000',
      hover: '#171719'
    }
  },
  notification: {
    error: '#DE5D57',
    success: '#4caf50'
  },
  card: {
    primary: '#242428'
  },
  blotter: {
    union: '#3a2f15'
  },
  price: {
    up: '#2BDF68',
    down: '#FD7557',
    same: '#707079'
  }
}

export default palette
