import React from 'react'
import { Stack } from '@mui/material'
import { IconListRule, TextRule } from './UpdatePassword.style'
import { Row } from '../../components/styled/global.style'

export const rules = [
  <Row key={1}>
    <IconListRule />
    <TextRule>At Least 8 characters</TextRule>
  </Row>,
  <Row key={2}>
    <IconListRule />
    <TextRule>1 number (1,2,3,4,5,6,7,8,9,0)</TextRule>
  </Row>,
  <Row key={3}>
    <IconListRule />
    <Stack>
      <TextRule>1 Non-alphanumeric ASCII characters</TextRule>
      <TextRule>{'(!@#$%^& *.:;~\'`"*/+?- _|=()[] {}<>)'}</TextRule>
    </Stack>
  </Row>
]
