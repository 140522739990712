import { Button, ButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

export const OptionContainer = styled('li')(() => ({
  display: 'flex !important',
  justifyContent: 'space-between !important',
  '&:hover': {
    backgroundColor: 'black'
  }
}))

export const SideButton = styled(Button)(({ theme, select }) => {
  let backgroundColorHover = theme.palette.tabs.backgroundColor.hover
  let backgroundColor = theme.palette.tabs.backgroundColor.primary
  let color = theme.palette.button.edit.primary
  if (select === 'true') {
    backgroundColor = theme.palette.button.edit.primary
    color = theme.palette.tabs.backgroundColor.primary
    backgroundColorHover = theme.palette.button.edit.hover
  }

  return {
    flex: 1,
    border: `1px solid ${theme.palette.button.edit.primary}`,
    borderRadius: '4px',
    color,
    fontSize: '14px',
    backgroundColor,
    '&:hover': {
      border: `1px solid ${theme.palette.button.edit.primary}`,
      backgroundColor: `${backgroundColorHover}`
    }
  }
})

export const ButtonGroupStyle = styled(ButtonGroup)(() => ({
  display: 'flex',
  width: '100%'
}))
