import { Paper, styled } from '@mui/material'

export const PaperStyle = styled(Paper)(({ theme }) => {
  return {
    backgroundColor: theme.palette.separators.primary,
    height: '150px',
    overflowY: 'auto',
    position: 'absolute',
    listStyleType: 'none',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '20px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.switch.unactive,
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.svg.clearIcon,
      borderRadius: '4px',
      width: '6px',
      height: '6px'
    },
    '& li:hover': {
      backgroundColor: theme.palette.modal.primary
    }
  }
})
