import React from 'react'
import { PaperStyle } from './PaperAutoComplete.style'

const PaperAutoComplete = React.forwardRef((props, ref) => {
  return <PaperStyle {...props}></PaperStyle>
})

PaperAutoComplete.displayName = 'PaperAutoComplete'

export default PaperAutoComplete
