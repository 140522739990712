import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { styled, Typography } from '@mui/material'

export const PagePrevIcon = styled(KeyboardArrowLeftIcon)(({ theme }) => ({
  color: theme.palette.svg.clearIcon,
  fontSize: '30px',
  '& .Mui-disabled': {
    color: theme.palette.button.disable
  }
}))

export const PageNextIcon = styled(KeyboardArrowRightIcon)(({ theme }) => ({
  color: theme.palette.svg.clearIcon,
  fontSize: '30px',
  '& .Mui-disabled': {
    color: theme.palette.button.disable
  }
}))

export const PageNumberText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.svg.clearIcon,
    fontFamily: 'Nunito',
    fontWeight: 500,
    fontSize: '20px',
    marginLeft: '8px',
    marginRight: '24px'
  }
})

export const PageText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.button.disable,
    fontFamily: 'Nunito',
    fontWeight: 500,
    fontSize: '20px',
    marginLeft: '24px'
  }
})
