import { styled } from '@mui/system'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

export const DateTimePickerStyle = styled(DateTimePicker)(({ theme }) => ({
  overflow: 'hidden',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '20px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#707079',
    width: '6px',
    height: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#babec7',
    borderRadius: '4px',
    width: '6px',
    height: '6px'
  },
  '&.MuiInputBase-root': {
    '&.Mui-disabled': {
      color: 'white !important' // Text color when disabled
    }
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.button.save.primary
  },
  '& .MuiSvgIcon-root.Mui-disabled': {
    fill: 'grey' // Adjust fill color for the disabled state
  },
  '& .Mui-error': {
    border: 'none' // Adjust fill color for the disabled state
  }
}))
