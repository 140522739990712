import React,{ Menu } from "@mui/material";
import { styled } from "@mui/material";
export const StyledMenu = styled(props => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    />
  ))(({ theme, style = {}, backgroundColor = '#171719' }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      backgroundColor: backgroundColor,
      marginTop: theme.spacing(1),
      minWidth: 180,
      ...style,
      color: theme.palette.text.primary,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:hover': {
          backgroundColor: theme.palette.tabs.backgroundColor.hover
        }
      },
      '& .MuiList-root': {
        ...style,
        backgroundColor: 'transparent'
      }
    }
  }))