import React from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../../redux/slices/Modal/modalSlice'
import { CancelButton } from '../../../styled/global.style'

const ModalCancelButton = () => {
  const dispatch = useDispatch()

  const closeModalApp = () => {
    dispatch(closeModal())
  }

  return <CancelButton onClick={closeModalApp}>Cancel</CancelButton>
}

export default ModalCancelButton
