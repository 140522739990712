import { styled } from '@mui/system'
import { Button, Typography, FormLabel } from '@mui/material'

export const Logo = styled(Typography)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '52px',
  height: '61px',
  width: '52px'
}))

export const SignInButton = styled(Button)(({ theme }) => ({
  width: '252px',
  height: '40px',
  backgroundColor: theme.palette.button.save.primary,
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: 'Nunito',
  fontSize: '14px',
  fontStyle: 'normal',
  padding: '11px 24px',
  letterSpacing: '0px',
  color: 'black',
  marginTop: '48px',
  marginBottom: '40px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.button.save.hover,
    color: 'black'
  },
  '&:disabled': {
    background: theme.palette.button.disable,
    color: theme.palette.svg.clearIcon
  }
}))

export const FromLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px'
}))

export const ForgotPasswordLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.button.save.primary,
  textAlign: 'end',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.button.save.hover
  }
}))
