import React, { useState } from 'react'
import { HeaderWarper, StyleLogoutIcon, StyleMenuItem, Title, UserIcon } from './Header.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, IconButton, Menu, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../redux/slices/Auth/AuthSlice'
import DailyPnl from './components/DailyPnl/DailyPnl'
const Header = () => {
  const title = useLocation().pathname.substring('1').replaceAll('/', ' ')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handelClick = () => {
    setOpen(!open)
  }

  const handelLogout = () => {
    dispatch(userLogout(navigate))
  }

  return (
    <HeaderWarper id="header">
      <Title>{title}</Title>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DailyPnl />
        <IconButton
          onClick={handelClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <UserIcon />
        </IconButton>
        <Menu
          id="account-menu"
          open={open}
          onClose={handelClick}
          onClick={handelClick}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                backgroundColor: '#39393C',
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 5,
                '&::before': {
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 20,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <StyleMenuItem onClick={handelLogout}>
            <StyleLogoutIcon />
            <Typography style={{ color: 'white', marginLeft: '12px' }}>Log out</Typography>
          </StyleMenuItem>
        </Menu>
      </Box>
    </HeaderWarper>
  )
}

export default Header
