import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const PnlDailyContainer = styled(Box)(({ theme }) => ({
  padding: '10px',
  fontSize: '14px',
  borderRadius: '4px',
  backgroundColor: theme.palette.button.allocate.primary,
  color: theme.palette.button.text.primary
}))
