const currencyList = [
  {
    action: 'new',
    security: 'ILSUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 1,
    counter_currency: 'USD',
    base_currency: 'ILS',
    counter_currency_country: 'USA',
    base_currency_country: 'ISR'
  },
  {
    action: 'new',
    security: 'HKDUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 2,
    counter_currency: 'USD',
    base_currency: 'HKD',
    counter_currency_country: 'USA',
    base_currency_country: 'HKG'
  },
  {
    action: 'new',
    security: 'EURUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 3,
    counter_currency: 'USD',
    base_currency: 'EUR',
    counter_currency_country: 'USA',
    base_currency_country: 'EUR'
  },
  {
    action: 'new',
    security: 'MXNUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 4,
    counter_currency: 'USD',
    base_currency: 'MXN',
    counter_currency_country: 'USA',
    base_currency_country: 'MEX'
  },
  {
    action: 'new',
    security: 'TRYUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 5,
    counter_currency: 'USD',
    base_currency: 'TRY',
    counter_currency_country: 'USA',
    base_currency_country: 'TUR'
  },
  {
    action: 'new',
    security: 'XAUUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 6,
    counter_currency: 'USD',
    base_currency: 'XAU',
    counter_currency_country: 'USA',
    base_currency_country: ''
  },
  {
    action: 'new',
    security: 'ZARUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 7,
    counter_currency: 'USD',
    base_currency: 'ZAR',
    counter_currency_country: 'USA',
    base_currency_country: 'ZAF'
  },
  {
    action: 'new',
    security: 'JPYUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 8,
    counter_currency: 'USD',
    base_currency: 'JPY',
    counter_currency_country: 'USA',
    base_currency_country: 'JPN'
  },
  {
    action: 'new',
    security: 'HUFUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 9,
    counter_currency: 'USD',
    base_currency: 'HUF',
    counter_currency_country: 'USA',
    base_currency_country: 'HUN'
  },
  {
    action: 'new',
    security: 'CZKUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 10,
    counter_currency: 'USD',
    base_currency: 'CZK',
    counter_currency_country: 'USA',
    base_currency_country: 'CZE'
  },
  {
    action: 'new',
    security: 'CHFUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 11,
    counter_currency: 'USD',
    base_currency: 'CHF',
    counter_currency_country: 'USA',
    base_currency_country: 'CHE'
  },
  {
    action: 'new',
    security: 'SEKUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 12,
    counter_currency: 'USD',
    base_currency: 'SEK',
    counter_currency_country: 'USA',
    base_currency_country: 'SWE'
  },
  {
    action: 'new',
    security: 'AUDUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 13,
    counter_currency: 'USD',
    base_currency: 'AUD',
    counter_currency_country: 'USA',
    base_currency_country: 'AUS'
  },
  {
    action: 'new',
    security: 'CADUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 14,
    counter_currency: 'USD',
    base_currency: 'CAD',
    counter_currency_country: 'USA',
    base_currency_country: 'CAN'
  },
  {
    action: 'new',
    security: 'PLNUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 15,
    counter_currency: 'USD',
    base_currency: 'PLN',
    counter_currency_country: 'USA',
    base_currency_country: 'POL'
  },
  {
    action: 'new',
    security: 'NZDUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 16,
    counter_currency: 'USD',
    base_currency: 'NZD',
    counter_currency_country: 'USA',
    base_currency_country: 'NZL'
  },
  {
    action: 'new',
    security: 'SGDUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 17,
    counter_currency: 'USD',
    base_currency: 'SGD',
    counter_currency_country: 'USA',
    base_currency_country: 'SGP'
  },
  {
    action: 'new',
    security: 'NOKUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 18,
    counter_currency: 'USD',
    base_currency: 'NOK',
    counter_currency_country: 'USA',
    base_currency_country: 'NOR'
  },
  {
    action: 'new',
    security: 'CNHUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 19,
    counter_currency: 'USD',
    base_currency: 'CNH',
    counter_currency_country: 'USA',
    base_currency_country: 'CHN'
  },
  {
    action: 'new',
    security: 'GBPUSD Curncy',
    attributes: ['LAST_PRICE'],
    order_id: 20,
    counter_currency: 'USD',
    base_currency: 'GBP',
    counter_currency_country: 'USA',
    base_currency_country: 'GBR'
  }
]

export default currencyList
