import React, { useCallback, useEffect, useState, useTransition } from 'react'
import { webSocketWorkerEvent } from '../../services'
import Loader from '../../components/Loader/Loader'
import { PaperStyle, TradeBlotterTool } from '../TradeBlotter/TradeBlotter.style'
import { AgGridReact } from 'ag-grid-react'
import { changeUtcTimeToLocal, cleanEmptyField, paginationHandler } from '../../helper'
import { DATE_FORMAT, SIGN } from '../../utils/constants'
import Pagination from '../../components/Pagination/Pagination'
import SearchTextField from '../../components/SearchTextField/SearchTextField'
import { columnsDefs, defaultColDef } from './agGrid.setting'
import MenuFilter from './components/MenuFilter/MenuFilter'

const DealExcelDownload = () => {
  const initialFilterState = useCallback(() => {
    return {
      search: '',
      date: '',
      offset: 0
    }
  }, [])
  const [loader, setLoader] = useState(false)
  const [response, setResponse] = useState(false)
  const [page, setPage] = useState(1)
  const [disabled, setDisabled] = useState({ prev: true, next: true })
  const [filters, setFilters] = useState(initialFilterState())
  const [, startTransition] = useTransition()
  useEffect(() => {
    setLoader(true)
    webSocketWorkerEvent.sendEvent({ type: 'get_deal_excel_files', data: {} })
  }, [])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message.data.type === 'get_deal_excel_files' &&
        message.data.status === 'ok' &&
        message.data.data &&
        Array.isArray(message.data.data)
      ) {
        let { status, data } = message.data
        for (const element of data) {
          element.file = 'makor_fx_'.concat(
            changeUtcTimeToLocal(element.deal_report_date, DATE_FORMAT.DEAL_EXCEL_REPORT_DATE)
          )
        }
        paginationHandler(data, page, { setDisabled, disabled })
        setResponse({ status, data })
        setLoader(false)
      }
    }
    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent, page])

  const handelPagination = sign => {
    const ITEM_PAGE = 100
    const copyFilters = JSON.parse(JSON.stringify(filters))
    const newOffset = sign === SIGN.NEXT ? filters.offset + ITEM_PAGE : filters.offset - ITEM_PAGE
    const newPage = sign === SIGN.NEXT ? page + 1 : page - 1
    copyFilters.offset = newOffset
    if (newPage === 1) {
      setDisabled({ ...disabled, prev: true })
    }
    if (newPage >= 2) {
      setDisabled({ prev: false, next: false })
    }
    setPage(newPage)
    setLoader(true)
    setFilters(copyFilters)
    cleanEmptyField(copyFilters)
    webSocketWorkerEvent.sendEvent({
      type: 'get_deal_excel_files',
      data: copyFilters
    })
  }

  const handelFilters = event => {
    startTransition(() => {
      const { value, name } = event.target
      setPage(1)
      const copyFilters = JSON.parse(JSON.stringify(filters))
      if (name === 'search') {
        copyFilters.search = value
        copyFilters.offset = 0
        setFilters(copyFilters)
        cleanEmptyField(copyFilters)
        webSocketWorkerEvent.sendEvent({
          type: 'get_deal_excel_files',
          data: copyFilters
        })
      } else if (name === 'date') {
        copyFilters.offset = 0
        copyFilters.date = value
        setFilters(copyFilters)
        cleanEmptyField(copyFilters)
        webSocketWorkerEvent.sendEvent({
          type: 'get_deal_excel_files',
          data: copyFilters
        })
      }
    })
  }

  if (response.status === 'ok') {
    return (
      <PaperStyle className={'ag-theme-quartz-dark'}>
        <TradeBlotterTool>
          <SearchTextField name="search" handleChange={handelFilters} state={filters.search} />
          <MenuFilter filters={filters} handelFilters={handelFilters} />
        </TradeBlotterTool>
        {loader && <Loader />}
        {!loader && response.status === 'ok' && (
          <>
            <AgGridReact
              columnDefs={columnsDefs}
              rowData={response.data}
              defaultColDef={defaultColDef}
            />
            <Pagination
              page={page}
              handelNextPagination={() => handelPagination(SIGN.NEXT)}
              handelPrevPagination={() => handelPagination(SIGN.PREV)}
              disabledPrev={disabled.prev}
              disabledNext={disabled.next}
            />
          </>
        )}
      </PaperStyle>
    )
  }
}

export default DealExcelDownload
