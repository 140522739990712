import { useEffect } from 'react'
import currencyList from '../../../constants/currencyList'
import { webSocketWorkerPriceEvent } from '../../../services'
import { createBloombergCurrency } from '../../../helper'

const useBloombergSetting = ({ tradeBlotterResponse, data, setData, setMenuPriceLoading }) => {
  const setDataWithLogic = newData => {
    setData(prevData => {
      const index = prevData?.findIndex(price => price.order_id === newData.order_id)
      if (index !== -1) {
        if (newData.LAST_PRICE !== '') {
          if (newData.LAST_PRICE > prevData[index].LAST_PRICE) {
            newData.status = 'UP'
          } else if (newData.LAST_PRICE < prevData[index].LAST_PRICE) {
            newData.status = 'DOWN'
          } else {
            newData.status = 'SAME'
          }
          const updatedData = [...prevData]
          updatedData[index] = newData
          return updatedData
        } else {
          return prevData
        }
      } else {
        if (newData.LAST_PRICE !== '') {
          newData.status = 'UP'
          return [...prevData, newData]
        } else {
          return prevData
        }
      }
    })
  }

  const getUniqueStrings = deals => {
    const results = []
    const addedStrings = new Set() // Initialize Set for tracking unique strings
    deals.forEach(deal => {
      if (deal.trades?.length > 0 && deal.staging_status === 'Live' && deal.status === null) {
        // initial blumberg currency list
        if (deal.counter_currency !== 'USD') {
          const stringToAdd = `${deal.counter_currency}USD Curncy`
          const resultObject = createBloombergCurrency(deal)
          if (!addedStrings.has(stringToAdd)) {
            addedStrings.add(stringToAdd)
            results.push(resultObject)
          }
        }
      }
    })

    return results
  }

  const getBloombergPriceByCurrency = currency => {
    if (Array.isArray(data)) {
      let findCurrency = data.find(cu => {
        let base = cu.base_currency
        let counter = cu.counter_currency
        if (base + counter === currency) {
          return cu
        }
      })
      return findCurrency
    }
  }

  tradeBlotterResponse.data?.map(deal => {
    if (deal.trades?.length > 0 && deal.staging_status === 'Live' && deal.status === null) {
      if (deal.counter_currency !== 'USD') {
        let counter = deal.counter_currency + 'USD'
        let result = getBloombergPriceByCurrency(counter)
        deal.pnl_usd = deal.pnl * (result?.LAST_PRICE ?? null)
      } else {
        deal.pnl_usd = deal.pnl * 1
      }
    }
  })
  useEffect(() => {
    if (Array.isArray(tradeBlotterResponse.data) && tradeBlotterResponse.status === 'ok') {
      const uniqueStrings = getUniqueStrings(tradeBlotterResponse.data)
      const existingSecurities = new Set(currencyList?.map(item => item.security))

      uniqueStrings.forEach(unique => {
        if (!existingSecurities.has(unique.security)) {
          currencyList.push(unique)
          existingSecurities.add(unique.security)
        }
      })
      webSocketWorkerPriceEvent.sendEvent(currencyList)
    }
  }, [tradeBlotterResponse])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message?.data &&
        message.data.security !== 'connection_status' &&
        message.data.security !== undefined
      ) {
        setDataWithLogic(message.data)
        setMenuPriceLoading(false)
      }
    }
    webSocketWorkerPriceEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerPriceEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerPriceEvent])
}

export default useBloombergSetting
