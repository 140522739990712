import React, { useCallback, useEffect, useState, useTransition } from 'react'
import Loader from '../../components/Loader/Loader'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import { webSocketWorkerEvent } from '../../services'
import Pagination from '../../components/Pagination/Pagination'
import { PaperStyle, TradeBlotterTool } from '../TradeBlotter/TradeBlotter.style'
import { DATE_FORMAT, SIGN } from '../../utils/constants'
import { changeUtcTimeToLocal, cleanEmptyField, paginationHandler } from '../../helper'
import { columnsDefs, defaultColDef } from './agGrid.setting'
import FilterTable from '../TradeBlotter/components/FilterTable/FilterTable'
import SearchTextField from '../../components/SearchTextField/SearchTextField'

const TradeDelete = () => {
  const initialFilterState = useCallback(() => {
    return {
      id: '',
      trade_date_start: '',
      trade_date_end: '',
      value_date_start: '',
      value_date_end: '',
      search: '',
      type: [],
      symbol: [],
      prime_broker: [],
      counterparty: [],
      offset: 0
    }
  }, [])
  const [loader, setLoader] = useState(false)
  const [response, setResponse] = useState(false)
  const [page, setPage] = useState(1)
  const [disabled, setDisabled] = useState({ prev: true, next: true })
  const [filters, setFilters] = useState(initialFilterState())
  const [, startTransition] = useTransition()
  useEffect(() => {
    setLoader(true)
    webSocketWorkerEvent.sendEvent({ type: 'get_delete_trade', data: {} })
  }, [])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message.data.type === 'get_delete_trade' &&
        message.data.status === 'ok' &&
        message.data.data &&
        Array.isArray(message.data.data)
      ) {
        let { status, data } = message.data
        for (const element of data) {
          element.trade_date = changeUtcTimeToLocal(
            element.trade_date,
            DATE_FORMAT.BLOTTER_DATE_TIME
          )
        }
        paginationHandler(data, page, { setDisabled, disabled })

        setResponse({ status, data })
        setLoader(false)
      }
    }
    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  const handelPagination = sign => {
    const ITEM_PAGE = 100
    const copyFilters = JSON.parse(JSON.stringify(filters))
    const newOffset = sign === SIGN.NEXT ? filters.offset + ITEM_PAGE : filters.offset - ITEM_PAGE
    const newPage = sign === SIGN.NEXT ? page + 1 : page - 1
    copyFilters.offset = newOffset
    if (newPage === 1) {
      setDisabled({ ...disabled, prev: true })
    }
    if (newPage >= 2) {
      setDisabled({ prev: false, next: false })
    }
    setPage(newPage)
    setLoader(true)
    setFilters(copyFilters)
    cleanEmptyField(copyFilters)
  }

  const handelGlobalSearchFilter = event => {
    startTransition(() => {
      const { value } = event.target
      setPage(1)
      const copyFilters = JSON.parse(JSON.stringify(filters))
      copyFilters.search = value
      copyFilters.offset = 0
      setFilters(copyFilters)
      cleanEmptyField(copyFilters)
      webSocketWorkerEvent.sendEvent({
        type: 'get_delete_trade',
        data: copyFilters
      })
    })
  }

  return (
    <PaperStyle className={'ag-theme-quartz-dark'}>
      <TradeBlotterTool>
        <SearchTextField
          name="search"
          handleChange={handelGlobalSearchFilter}
          state={filters.search}
        />
        <FilterTable
          page={page}
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
          cleanCheckBoxState={() => {}}
          WSMessageType={'get_delete_trade'}
        />
      </TradeBlotterTool>
      {loader && <Loader />}
      {!loader && response.status === 'ok' && (
        <>
          <AgGridReact
            columnDefs={columnsDefs}
            rowData={response.data}
            defaultColDef={defaultColDef}
          />
          <Pagination
            page={page}
            handelNextPagination={() => handelPagination(SIGN.NEXT)}
            handelPrevPagination={() => handelPagination(SIGN.PREV)}
            disabledPrev={disabled.prev}
            disabledNext={disabled.next}
          />
        </>
      )}
    </PaperStyle>
  )
}

export default TradeDelete
