import { Box, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import FlagCurrency from '../../../../components/FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../components/CurrencyLogo/CurrencyLogo'

const CurrencyPairCell = ({
  base_currency,
  base_currency_country,
  counter_currency,
  counter_currency_country
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        height: '100%'
      }}
    >
      <Typography>{base_currency}</Typography>
      <FlagCurrency countryCode={base_currency_country} />
      <CurrencyLogo currencyCode={base_currency} />
      <Typography>/</Typography>
      <Typography>{counter_currency}</Typography>
      <FlagCurrency countryCode={counter_currency_country} />
      <CurrencyLogo currencyCode={counter_currency} />
    </Box>
  )
}

export default CurrencyPairCell

CurrencyPairCell.propTypes = {
  base_currency: PropTypes.string,
  base_currency_country: PropTypes.string,
  counter_currency: PropTypes.string,
  counter_currency_country: PropTypes.string
}
