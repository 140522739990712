/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handelNotifications } from '../redux/slices/Notification/notificationSlice'
import { closeModal, setGetRequest } from '../redux/slices/Modal/modalSlice'
import { userLogout } from '../redux/slices/Auth/AuthSlice'
import { areObjectsEqual } from '../helper'
import { useNavigate } from 'react-router-dom'

function useAxios({ endPoint, params, modalType }) {
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const modalTypeState = useSelector(state => state.modal.type)
  const token = useSelector(state => state.auth.token)
  const modalGetRequestState = useSelector(state => state.modal.getRequest)
  const url = `${process.env.REACT_APP_SERVER_URL}${endPoint}`
  const [oldParams, setOldParams] = useState({ search: undefined })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getRequestData = async () => {
    const request = {
      url,
      params,
      headers: {
        Authorization: token
      }
    }
    try {
      setLoading(true)
      const apiResult = await axios.request(request)
      setResponse({ status: apiResult.status, data: apiResult.data })
    } catch (error) {
      dispatch(userLogout(navigate))
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'sorry something wrong happen'
        })
      )
    } finally {
      setLoading(false)
    }
  }

  const sendFormData = async ({ method, data, sendEndPoint }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}${sendEndPoint}`
    try {
      const request = {
        method,
        url,
        data,
        headers: {
          Authorization: token
        }
      }
      const result = await axios.request(request)
      dispatch(
        handelNotifications({ id: Date.now(), type: 'success', message: result.data.message })
      )
      dispatch(setGetRequest(true))
      setTimeout(() => {
        dispatch(closeModal())
      }, 0)
    } catch (error) {
      if (Array.isArray(error?.response?.data))
        for (const { message } of error.response.data) {
          dispatch(closeModal())
          dispatch(
            handelNotifications({
              id: Date.now(),
              type: 'error',
              message: message
            })
          )
        }
    }
  }

  useEffect(() => {
    if (endPoint) {
      getRequestData()
    }
  }, [])

  useEffect(() => {
    if (endPoint && params && (params.search || !areObjectsEqual(params, oldParams))) {
      getRequestData()
      setOldParams(params)
    }
  }, [params])

  useEffect(() => {
    if (endPoint && modalType && modalTypeState === modalType && modalGetRequestState) {
      getRequestData()
      dispatch(setGetRequest(false))
    }
  }, [modalGetRequestState, modalTypeState])

  return { response, setResponse, loading, sendFormData }
}

export default useAxios
